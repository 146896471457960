<template>
  <v-alert
    class="alert-projet"
    :type="!typeError ? 'success' : 'error'"
    dense
    prominent
    tile
    outlined
    text
    transition="scale-transition"
    min-width="307"
    max-width="500"
    border="left"
    dismissible
  >
    <div v-if="message && typeError">
      <ul v-if="Array.isArray(message)">
        <li
          v-for="(e, index) in message"
          :key="index"
          class="font-sz-13 bold-500"
        >
          {{ e }}
        </li>
      </ul>
      <span v-else class="font-sz-13 bold-500">{{ message }}</span>
    </div>
    <div v-else class="font-sz-13 bold-500">Modifié avec succès.</div>
  </v-alert>
</template>
<script>
export default {
  props: {
    message: { required: true },
    typeError: { required: true }
  }
}
</script>
<style lang="scss">
.v-alert {
  // top: 7% !important;
  bottom: 10% !important;
  height: max-content !important;
  position: absolute !important;
  right: 0px !important;
  z-index: 1 !important;
  .mdi-close-circle::before {
    content: '\F0156' !important;
    font-size: 17px !important;
  }
}
.v-alert--text {
  background: #ffffff !important;
}
.v-alert--prominent .v-alert__icon.v-icon {
  font-size: 22px !important;
  height: 24px !important;
  min-width: 24px !important;
}
</style>
