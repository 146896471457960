<template>
  <ol class="stepper-component">
    <li
      v-for="(step, index) in options"
      :key="index"
      class="stepProgressBar__step"
      :class="{
        'stepProgressBar__step--active': step.completed
      }"
    >
      <div v-if="index > 0" class="stepProgressBar__step__line"></div>

      <button
        class="stepProgressBar__step__button"
        type="button"
        @click="step.clickFunction(step)"
      >
        <span class="stepProgressBar__step__button__indicator"> </span>
        <span class="stepProgressBar__step__button__label ">
          {{ step.name }}
        </span>
        <span
          class="stepProgressBar__step__button__sub__label"
          :class="{
            laststepProgressBar__step__button__sub__label:
              index > 0 && index == options.length - 1
          }"
          v-if="step.sub_title"
        >
          {{ step.sub_title.name }}
        </span>
      </button>
    </li>
  </ol>
</template>

<script>
export default {
  props: {
    options: { required: true }
  },
  data() {
    return {}
  }
}
</script>

<style lang="scss">
/* Component styles */
$stepProgressBar-indicator-size: 22px;
$stepProgressBar-font-size: 14px;
$stepProgressBar-step-size: 80px;
$stepProgressBar-step-line-size: 5px;
$stepProgressBar-color-primary: #45148f;
$stepProgressBar-color-secondary: #d1d5db;
$stepProgressBar-color-neutral: #fff;
$stepProgressBar-color-neutral-400: #a3a3a3;
$stepProgressBar-color-neutral-800: #262626;

.stepper-component {
  display: flex;
  width: 100%;
  padding-top: 29px;
  .stepProgressBar__step__button__sub__label {
    white-space: nowrap;
    font-size: 11px !important;

    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: $stepProgressBar-color-primary;
  }
  .laststepProgressBar__step__button__sub__label {
    position: relative;
    left: calc(-50% + 10px);
  }
  .stepProgressBar__step__button__label {
    position: absolute;
    top: -24px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 11px;
    padding-top: 5px;

    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: #2f2f2f;
  }
  .stepProgressBar__step {
    display: flex;

    &:not(:first-child) {
      width: 100%;
    }

    .stepProgressBar__step__button {
      align-items: center;
      color: $stepProgressBar-color-neutral-400;
      display: flex;
      font-size: $stepProgressBar-font-size;
      flex-direction: column;
      flex-shrink: 0;
      position: relative;
      transition: color 0.2s ease-in;
      width: $stepProgressBar-step-size;

      .stepProgressBar__step__button__indicator {
        align-items: center;
        background-color: $stepProgressBar-color-neutral;
        border: calc(#{$stepProgressBar-indicator-size} / 4) solid
          $stepProgressBar-color-secondary;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        height: $stepProgressBar-indicator-size;
        transition: background-color 0.3s ease-in;
        width: $stepProgressBar-indicator-size;
      }
    }
  }
  .stepProgressBar__step--active {
    color: $stepProgressBar-color-neutral-800;

    .stepProgressBar__step__button__indicator {
      background-color: $stepProgressBar-color-neutral !important;
      border: calc(#{$stepProgressBar-indicator-size} / 4) solid
        $stepProgressBar-color-primary !important;
      transition: all 0.3s ease-in !important;
    }

    .stepProgressBar__step__line {
      background-color: $stepProgressBar-color-primary !important;
      transition: background-color 0.2s ease-in !important;
    }
  }

  .stepProgressBar__step__line {
    background-color: $stepProgressBar-color-secondary;
    height: $stepProgressBar-step-line-size;
    margin-top: calc(
      #{$stepProgressBar-indicator-size} / 2 - #{$stepProgressBar-step-line-size} /
        2
    );
    margin-left: calc(#{$stepProgressBar-step-size} / 2 * -1);
    margin-right: calc(#{$stepProgressBar-step-size} / 2 * -1);
    transition: background-color 0.3s ease-in;
    width: 100%;
  }
}
</style>
