<template>
  <div class="list-task-immo">
    <div
      v-for="(i, index) in options"
      :key="'action-' + index"
      class="item-list"
      @click="hendelClickItem(i)"
      :class="{ 'border-right': index < options.length }"
    >
      <font-awesome-icon
        v-if="i.icon"
        :icon="[getIconFormate(i.icon, 0), getIconFormate(i.icon, 1)]"
      />
      <div class="item-title">
        {{ i.designation }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: { options: { default: [] } },
  computed: {
    ...mapGetters(['getIconFormate'])
  },
  methods: {
    hendelClickItem(item) {
      this.$emit('clickItem', item)
    }
  }
}
</script>

<style scoped lang="scss">
.list-task-immo {
  display: flex;
  overflow-x: auto;
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .item-list {
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    font-weight: 500;
    display: flex;
    min-width: 74px;
    line-height: 1rem;
    gap: 7px;
    letter-spacing: 0px;
    color: #2f2f2f;
    padding: 0px 12px 0px 12px;
    align-items: center;
    justify-content: center;
    &:hover {
      background: #f1f5f9;
    }
    .item-title {
      // height: 12px;
      font-size: 12px;
      line-height: 9px;
    }
    .svg-inline--fa {
      height: 1.2em !important;
    }
  }
  .border-right {
    border-right: 1px solid #d4d3dba6;
  }
}
</style>
