<template>
  <div class="top-menu-table">
    <div class="header-block">
      <div class="left-header">
        <div class="list-class-proposals">
          <div class="item-list pt-3 pb-3">
            <edit-view
              :list="computedListView"
              :handleDropdownItemClick="handleDropdownItemClick"
              :updateOrderFields="updateOrderView"
            ></edit-view>
          </div>
          <!-- ---------------------------- RESOURCE ACTIVE ET DESACTIVE ----------------------------------->
          <div :class="{ 'item-list': showActionHeaderEditable || showBtnAdd }">
            <v-switch
              :label="switchLabel"
              color="#45148F"
              @change="handlClickActive"
              v-model="activeResource"
              :class="{
                'color-bold': activeResource
              }"
              class="ml-2 bold-700 input-checkbox switch-bottom label-switch mt-2 mb-2"
              hide-details
              :true-value="1"
              :false-value="0"
            >
            </v-switch>
          </div>
          <template v-if="showActionHeaderEditable">
            <div
              class="item-list color-red"
              @click.prevent.stop="handelDeleteMultiResource"
            >
              <font-awesome-icon :icon="['far', 'trash']" class="img-h-16" />
              <span class="mt-1"> {{ $t('deleted_projet') }}</span>
            </div>
            <div class="delete-projet ml-2 mt-3">
              {{
                $i18n.locale == 'fr'
                  ? `${selectcomputedTableBoardedCheck.length} Projets Sélectionné`
                  : `${selectcomputedTableBoardedCheck.length} Selected Projects`
              }}
            </div>
          </template>
          <template v-else>
            <div class="block-action-add-propoasls" v-if="showBtnAdd">
              <v-btn
                dark
                class="ml-3"
                color="#45148F"
                @click.prevent.stop="handleClickBtnAdd"
              >
                <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
                <span class="label-btn"> {{ $t('btnAdd') }} {{ label }} </span>
              </v-btn>
            </div>
          </template>
        </div>
      </div>
      <div class="right-header">
        <!-- ----------------   START LOADING / MESSAGE ERROR  ------------------------------>
        <div class="message mr-3">
          <v-progress-circular
            v-if="getResourceLoading"
            indeterminate
            color="#45148F"
            class="sz-loader-moyen-icon"
          ></v-progress-circular>
          <div v-if="hasError" class="error-msg">
            <ul v-if="Array.isArray(errorMessages)">
              <li v-for="(e, index) in errorMessages" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ errorMessages }}</span>
          </div>
        </div>
        <!-- ----------------   END LOADING / MESSAGE ERROR  ------------------------------>
        <div
          class="block-action-add-propoasls"
          v-if="showActionHeaderEditable && showBtnAdd"
        >
          <v-btn
            dark
            class="mr-3"
            color="#45148F"
            @click.prevent.stop="handleClickBtnAdd"
          >
            <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
            <span class="label-btn"> {{ $t('btnAdd') }} {{ label }} </span>
          </v-btn>
        </div>

        <div class="flex-center ml-2">
          <div
            class="btn-list mr-2"
            :class="{ active: selectedDefaultBtn == 'grid' }"
            @click.prevent.stop="showGrid"
          >
            <font-awesome-icon
              :icon="['fal', 'grid-2']"
              class="img-h-19 color-img"
            />
          </div>
          <div
            class="btn-list"
            :class="{ active: selectedDefaultBtn == 'list' }"
            @click.prevent.stop="showList"
          >
            <font-awesome-icon
              :icon="['fas', 'bars']"
              class="img-h-19 color-img"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import EditView from '@/views/CRMILA26/Projects/immobilier/pipline/filters/EditView.vue'
export default {
  components: { EditView },
  props: {
    label: { required: true },
    showActionHeaderEditable: { required: false },
    selectcomputedTableBoardedCheck: { required: true },
    selectedDefaultBtn: { required: true },
    typeRessourceId: { required: true },
    switchLabel: { required: true },
    showBtnAdd: { required: true }
  },
  data() {
    return {
      activeResource: 1,
      errorMessages: null
    }
  },
  methods: {
    ...mapActions(['UpdateViewTableResource', 'updateOrderFieldsResource']),
    handlClickActive(active) {
      this.activeResource = active
      this.$emit('handlClickSwitch', active)
    },
    handelDeleteMultiResource() {
      this.$emit('handelDeleteMultiResource')
    },
    handleClickBtnAdd() {
      this.$emit('handleClickBtnAdd')
    },
    showList() {
      this.$emit('showList')
    },
    showGrid() {
      this.$emit('showGrid')
    },
    async handleDropdownItemClick(item) {
      const payload = {
        type_ressource_id: this.typeRessourceId,
        field_id: item.id,
        order: item.order,
        visibility: item.visibility
      }
      await this.UpdateViewTableResource({ data: payload })
      this.$emit('refreshHeader')
    },
    async updateOrderView(list, item) {
      this.updateOrderFieldsResource(list)
      const payload = {
        type_ressource_id: this.typeRessourceId,
        field_id: list[item.addedIndex].id,
        order: item.addedIndex
      }
      await this.UpdateViewTableResource({ data: payload })
      this.$emit('refreshHeader')
    }
  },
  computed: {
    ...mapGetters([
      'getResourceLoading',
      'getTypeResource',
      'getResourceError',
      'getHeadersResource'
    ]),
    computedListView: function() {
      if (this.getHeadersResource && this.getHeadersResource.length) {
        return this.getHeadersResource.map(i => {
          return {
            text: i.name,
            value: i.key,
            ...i
          }
        })
      }

      return []
    },
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    }
  },
  watch: {
    getResourceError(newValue) {
      this.errorMessages = newValue
    }
  }
}
</script>
<style lang="scss" scoped>
.top-menu-table {
  .header-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-header {
      white-space: nowrap;
      .delete-projet {
        color: #45148f;
        font-family: 'Montserrat', sans-serif;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .right-header {
      display: flex;
      align-items: center;

      .btn-list {
        padding: 8px;
        gap: 10px;
        border-radius: 4px;
        width: 39px;
        height: 39px;
        text-align: center;
        &:hover,
        &.active {
          background-color: #ececec;
        }
      }
      .color-img {
        color: #7f8c8d !important;
      }
    }

    .block-action-add-propoasls {
      font-family: 'Montserrat', sans-serif;
      display: flex;
      line-height: 24px;
      gap: 5px;
      letter-spacing: 0px;
      border-right: 1px solid #c9c8d3;
      align-items: center;
      justify-content: flex-start;
      .label-btn {
        font-size: 12px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
        text-align: center;
        text-transform: capitalize !important;
        line-height: 17.2px;
      }
    }
  }
  .list-class-proposals {
    display: flex;
    .item-list {
      font-family: 'Montserrat', sans-serif;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      line-height: 24px;
      gap: 5px;
      letter-spacing: 0px;
      color: #2f2f2f;
      padding: 0px 9px 0px 6px;
      border-right: 1px solid #c9c8d3;
      align-items: center;
      justify-content: flex-start;
      &:hover {
        background: #f1f5f9;
      }
    }
  }
}
</style>
<style lang="scss">
.top-menu-table {
  .header-block {
    .block-action-add-propoasls {
      .v-btn:not(.v-btn--round).v-size--default {
        height: 33px !important;
      }
    }
  }
}
</style>
