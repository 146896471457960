<template>
  <div class="activity-component">
    <v-col cols="7" class="activity-block">
      <div class="task-header">
        <MenuHistoryActivity
          :loading="loading"
          :options="options"
          :projet="projet"
          @handlFilterActivity="handlFilterActivity"
        ></MenuHistoryActivity>
      </div>
      <div class="task-body">
        <HistoryActivity
          :history="getHistoryEventOfProject"
          :loading="getEventLoading"
          :countPagination="countPaginationHistoryEvent"
          :totalPage="totalPage"
          :perPage="per_page"
          @handlePagination="handlePagination"
        />
      </div>
    </v-col>
    <v-col cols="5" class="activity-block">
      <div class="task-header">
        <tabs
          :menu-items="menuActions"
          :menu-file="menuActionValue"
          @update:menu="$event => (menuActionValue = $event)"
        ></tabs>
        <div class="message">
          <v-progress-circular
            v-if="
              (menuActionValue == 'tab-0' && getEventLoading) ||
                (menuActionValue == 'tab-1' && getactionEventLoading)
            "
            indeterminate
            class="sz-loader-moyen-icon"
            color="#3a167f"
          ></v-progress-circular>
        </div>
        <div class="new-task" v-if="menuActionValue == 'tab-0'">
          <v-btn
            dark
            class="btn-immo-outline"
            @click.prevent.stop="handelNewTask"
          >
            <span class="label-btn"> {{ $t('new_task') }}</span>
          </v-btn>
        </div>
      </div>
      <div class="task-body">
        <taskCard
          v-if="menuActionValue == 'tab-0'"
          :events="getEventOfProject"
          :separator="false"
          @clickEvent="handelUpdateTask"
          :handelCheckFunction="updateStatusTask"
          :error="getEventError"
        ></taskCard>
        <div v-if="false">
          <action-card :data="getactionEvent"></action-card>
        </div>
      </div>
    </v-col>
  </div>
</template>

<script>
import taskCard from '../immoTable/component/taskCard.vue'
import tabs from '../immoTable/component/tabs.vue'
import HistoryActivity from './HistoryActivity.vue'
import MenuHistoryActivity from './MenuHistoryActivity.vue'
import { mapGetters, mapActions } from 'vuex'
import ActionCard from './actionCard.vue'
export default {
  props: {
    options: { required: true },
    projet: { required: false }
  },
  data() {
    return {
      errorMessages: null,
      page: 1,
      per_page: 5,
      loading: false,
      menuActions: [
        {
          key: 'task'
        }
        // {
        //   key: 'action'
        // }
      ],
      menuActionValue: 'tab-0'
    }
  },
  computed: {
    ...mapGetters([
      'getEventOfProject',
      'getEventError',
      'getEventLoading',
      'getHistoryEventOfProject',
      'countPaginationHistoryEvent',
      'getactionEventLoading',
      'getactionEvent'
    ]),
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    },
    totalPage() {
      return Math.ceil(this.countPaginationHistoryEvent / this.per_page)
    }
  },
  methods: {
    ...mapActions(['updateEvent', 'fetchHistoryActivtyEvent']),
    handlePagination(page) {
      this.page = page
      this.handlFilterActivity()
    },
    async handlFilterActivity(selectedActivity) {
      this.loading = true
      // Filter history based on the selected activity
      if (localStorage.getItem('typeProject')) {
        if (selectedActivity && selectedActivity.length) {
          let filtreSelectedValueNotNull = selectedActivity.filter(
            item => item != null
          )
          await this.fetchHistoryActivtyEvent({
            type_events: filtreSelectedValueNotNull,
            page: this.page,
            per_page: 5,
            type_projet_id: localStorage.getItem('typeProject'),
            projet_id: this.projet.id
          })
        } else {
          // Filter all activity histories
          await this.fetchHistoryActivtyEvent({
            page: this.page,
            per_page: 5,
            type_projet_id: localStorage.getItem('typeProject'),
            projet_id: this.projet.id
          })
        }
      }
      this.loading = false
    },
    handelNewTask() {
      this.$emit('newTask')
    },
    handelUpdateTask(event) {
      this.$emit('updateTask', event)
    },
    async updateStatusTask(event) {
      let eventToUpdate = { ...event }
      eventToUpdate.status = 'Terminé'
      await this.updateEvent({
        body: eventToUpdate,
        event: event
      })
    }
  },
  components: {
    taskCard,
    HistoryActivity,
    MenuHistoryActivity,
    tabs,
    ActionCard
  },
  watch: {
    getEventError(newValue) {
      this.errorMessages = newValue
    }
  },
  async mounted() {
    this.loading = true
    // Filter all activity histories
    await this.fetchHistoryActivtyEvent({
      page: 1,
      per_page: 5,
      type_projet_id: localStorage.getItem('typeProject'),
      projet_id: this.projet.id
    })
    this.loading = false
  }
}
</script>

<style scoped lang="scss">
.activity-component {
  width: calc(100% - 10px);
  display: flex;
  gap: 10px;
  min-height: 100%;
  .activity-block {
    border-radius: 8px;
    border: 1px solid #ece8f4;
    background: #ffffff;
    padding: 0px;
    cursor: pointer;
    .task-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e6e8ea;
      padding: 8px 15px;
      .title-task {
        font-family: 'Montserrat', sans-serif;
        font-size: 13.5px;
        font-weight: 600;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: left;
        color: #45148f;
      }
    }
  }
}
</style>
<style lang="scss">
.check-history-activity {
  width: 100% !important;
  .v-input--selection-controls__ripple {
    width: 0 !important;
    left: 0 !important;
    top: 0 !important;
  }
  .v-label {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #2f2f2f !important;
  }
}
</style>
