<template>
  <div>
    <ModalRightDynamic @closeModal="handleClose">
      <!-- HEADER  -->
      <template #title>
        <span v-if="DataEvent.type === 'email'">Email</span>
        <span v-else-if="DataEvent.type === 'message'">Message</span>
        <span v-else>{{ DataEvent.type }}</span>
      </template>
      <!-- BODY CONTENT  -->
      <template #content-form>
        <v-form
          ref="modalActionEvent"
          class="mt-2 formActionEventImmo"
          v-if="DataEvent"
        >
          <!-- TO ACTION  BlLOCK -->
          <template>
            <!-- TO EMAIL ACTION  BlLOCK -->
            <v-row
            style="font-weight: 600;"
              class="row"
              v-for="(item, index) in DataEvent.to"
              :key="'to' + index"
            >
              <v-col
                :cols="index == 0 ? '12' : '11'"
                class="pb-0 relative-class"
              >
                <v-text-field
                  v-if="DataEvent.type === 'email'"
                  :label="$t('to')"
                  dense
                  :disabled="isDisabled"
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="item.value"
                  item-color="#5C2DD3"
                >
                </v-text-field>
                <div
                  class="custom-component-title"
                  v-if="DataEvent.type === 'message'"
                >
                  {{ $t("to") }}
                </div>
                <vue-tel-input-vuetify
                  v-if="DataEvent.type === 'message'"
                  mode="international"
                  :disabled="isDisabled"
                  v-model="DataEvent.to[index].value"
                  :outlined="true"
                  wrapperClasses="customPhoneAction"
                  :persistent-placeholder="true"
                  placeholder=""
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  dense
                ></vue-tel-input-vuetify>
              </v-col>
              <v-col clos="2" v-if="index > 0">
                <font-awesome-icon
                  class="mt-3"
                  :icon="['far', 'xmark']"
                  @click.prevent.stop="DataEvent.to.splice(index, 1)"
              /></v-col>
            </v-row>
          </template>

          <!-- OBJET  -->
          <v-row class="row"
          style="font-weight: 600;"
              v-if="DataEvent.type === 'email'">
                  <v-col>
                    <v-text-field
                      :label="$t('subject')"
                      dense
                      :persistent-placeholder="true"
                      outlined
                      :disabled="isDisabled"
                      color="#5C2DD3"
                      v-model="DataEvent.titre"
                      item-color="#5C2DD3"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!--  EDITOR ACTION  --> 
          <v-row v-if="DataEvent.type==='email'">
            <v-col class="relative-class"
              ><div class="custom-component-title">{{ $t(title) }}</div>
              <Editor
                v-model="DataEvent.description"
                @files="handelFiles"
              ></Editor
            ></v-col>
          </v-row>


          <!--  TEXT AREA ACTION  -->
          <v-row style="font-weight: 600;" v-if="DataEvent.type==='message'">
            <v-col class="pb-0">
              <v-textarea
                dense
                v-model="DataEvent.description"
                :persistent-placeholder="true"
                rows="5"
                :disabled="isDisabled"
                outlined
                color="#5C2DD3"
                class="textArea"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <!-- FOOTER ACTION  -->
      <template #content-actions>
        <v-btn
          text
          class="ml-3"
          @click.prevent.stop="handleClose"
          variant="outline-secondary"
        >
          <span class="label-btn-form-immo color-black text-uppercase">
            {{ $t("btnCancel") }}</span
          >
        </v-btn></template
      >
    </ModalRightDynamic>
  </div>
</template>

<script>
import ModalRightDynamic from "@/views/CRMILA26/Projects/Dialogs/ModalRightDynamic.vue";
import { mapGetters } from "vuex";
export default {
  name: "update-stage-modal",
  props: {
    DataEvent: { required: true },
  },
  data() {
    return {
      isDisabled: true,
    };
  },
  computed: {
    ...mapGetters([]),
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
  },
  mounted() {},
  components: {
    ModalRightDynamic,
    Editor: () => import('../../immoTable/component/action/Editeur.vue'),

  },
  watch: {},
};
</script>
<style scoped lang="scss">
.formActionEventImmo {
  .btn-date-component {
    padding-left: calc(100% - 40px);
    .icon-alarme-for-date-picker {
      border-radius: 4px;
      background: #f6f6f6;
      border: 1px solid #e5e5e5;
      width: 38px;
      height: 36px;
      padding: 7px;
    }
  }
  .custom-component-title {
    position: absolute;
    top: 1px;
    height: 14px;
    left: 24px;
    background: #fff;
    z-index: 8;
    padding: 0px 2px;
  }
  .relative-class {
    position: relative;
  }
  .textArea {
    font-size: 13px !important;
  }
  .attachment-block {
    .file-icon {
      color: #45148f;
    }
    .details-attachment {
      font-size: 12px;
      font-weight: 400;
      line-height: px;
      letter-spacing: 0.17000000178813934px;
      text-align: left;
      color: rgba(0, 0, 0, 0.6);
    }
    .title-attachment {
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.15000000596046448px;
      text-align: left;
      color: rgba(0, 0, 0, 0.87);
    }
  }
  .menu-message {
    border: 1px solid #e5e5e5;
    margin: 4px 0px 0px 0px !important;
    border-radius: 4px;
    display: flex;
    gap: 10px;
    background: #f6f6f6;
  }
}
</style>
<style lang="scss">
#date-action {
  .field #CustomInput {
    padding-top: 0px;
  }
}
.customPhoneAction {
  fieldset,
  .v-input--is-focused fieldset {
    border: 1px solid #0000003b;
  }
  .v-input fieldset {
    border-left: none;
    border-radius: 0px 4px 4px 0px;
  }
  .country-code fieldset {
    border-right: none;
    border-left: 1px solid #0000003b;
    border-radius: 4px 0px 0px 4px;
  }
}
</style>
