<template>
  <div class="new-project-modal">
    <!-- v-click-outside="closeModal" -->
    <div class="new-project-modal-wrapper">
      <div class="title-modal border-bottom-solid">
        <span class="font-sz-14 bold-700 font-text color-black label-modal">
          <slot name="title"></slot>
        </span>
        <div>
          <v-btn icon fab small @click="closeModal"
            ><v-icon>mdi-close </v-icon></v-btn
          >
        </div>
      </div>
      <div class="form-new-project">
        <slot name="content-form"></slot>
        <!-- MESSAGE AND LOADING -->
        <div class="actions-msg-btn-modal">
          <slot name="content-message"></slot>
        </div>
      </div>

      <!-- ACTIONS BTN -->
      <div class="action-new-projet">
        <slot name="content-actions"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  created() {},
  name: 'new-project-modal',
  props: {},
  data() {
    return {}
  },
  methods: {
    ...mapActions([]),
    closeModal() {
      this.$emit('closeModal')
    }
  },
  computed: {
    ...mapGetters([])
  }
}
</script>

<style lang="scss" scoped>
.new-project-modal {
  position: fixed;
  height: calc(100% - 0px);
  width: 100%;
  top: 0px;
  border: 0;
  background: #d6d6d64a;
  right: 0;
  left: 0;
  z-index: 101;
  .close {
    position: absolute;
    font-size: 20px;
    top: 10px;
    right: 24px;
    cursor: pointer;
    z-index: 1;
  }

  .new-project-modal-wrapper {
    position: fixed;
    width: 500px;
    right: 0px;
    height: 100%;
    background-color: #fff;
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
    // padding: 30px;
    font-size: 13px;
    color: #5e5e5e;
    .title-modal {
      padding: 10px 24px;
      background-color: #f6f6f6;
    }
    .form-new-project {
      padding: 20px;
      position: relative;
      width: 100%;
      height: calc(100vh - 120px);
      border-bottom: 1px solid rgba(236, 232, 244, 1);
      overflow: auto;
    }
    .form-new-project::-webkit-scrollbar {
      width: 7px;
      height: 7px;
    }
    .form-new-project::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 4px;
      background-color: rgba(238, 238, 238, 1) !important;
    }
    .form-new-project::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(189, 189, 189, 1);
    }
    .action-new-projet {
      float: right;
      padding: 12px;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    .actions-msg-btn-modal {
      padding: 20px;
    }
    .margin-div {
      margin-top: -14px;
    }
    .description-bloc {
      position: relative;
      font-weight: 600;
      padding: 4px 10px;
    }
    .server-error {
      margin-top: 20px;
      color: #ff6961;
    }
  }
  .label-modal {
    white-space: nowrap;
    overflow: hidden;
    font-weight: 700 !important;
    width: 64vh;
    text-overflow: ellipsis;
  }
  &.regie {
    width: 800px;
    max-width: 100%;
    min-width: 400px;

    .new-project-modal-wrapper {
      overflow-y: auto;
    }
  }
}
</style>
