<template>
  <div class="overlay-modal" ref="modal">
    <portal to="new-project-modal">
      <ModalRightDynamic @closeModal="handleClose('openModal')">
        <template #title>
          <div class="font-sz-18 font-text bold-700 text-capitalize">
            {{ labelModal }}
          </div>
        </template>
        <template #content-form>
          <v-form ref="openModal" v-if="formDefault">
            <!-- TITLE  -->
            <v-text-field
              :label="$t('title')"
              dense
              :persistent-placeholder="true"
              outlined
              color="#5C2DD3"
              v-model="formDefault.titre"
              item-color="#5C2DD3"
              validate-on-blur
              :rules="[v => !!v || $t('title') + ' ' + $t('msgOblg')]"
            >
            </v-text-field>
            <!-- DATE -->
            <v-menu
              :close-on-content-click="false"
              offset-y
              min-width="288px !important"
              max-width="288px !important"
              :nudge-bottom="-20"
              @input="handleOpenDateDebut"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  ref="date"
                  v-model="formDefault.date"
                  label="Date"
                  outlined
                  readonly
                  clearable
                  dense
                  v-on="on"
                  v-bind="attrs"
                  :persistent-placeholder="true"
                  :placeholder="$t('msgSelectDate')"
                  prepend-inner-icon="mdi-calendar"
                  color="#5C2DD3"
                  validate-on-blur
                  :rules="[v => !!v || $t('Date') + ' ' + $t('msgOblg')]"
                ></v-text-field>
              </template>

              <v-date-picker
                @input="handleOpenDateDebut"
                v-model="formDefault.date"
                no-title
                locale="fr"
                color="#5C2DD3"
                header-color="#5C2DD3"
                event-color="704ad1"
              >
              </v-date-picker>
            </v-menu>
            <!-- TYPE ACTIVITY -->
            <v-autocomplete
              :clearable="true"
              color="#5C2DD3"
              :loading="getTypeEventLoading"
              :placeholder="
                $t('searchMsg', {
                  msg: $t('type_activity')
                })
              "
              item-color="#5C2DD3"
              v-model="formDefault.type_event_id"
              :items="getTypeEvent"
              :persistent-placeholder="true"
              :label="$t('type_activity')"
              item-text="designation"
              dense
              return-object
              item-value="id"
              :no-data-text="
                $t('noDataOptionMsg', {
                  option: $t('type_activity')
                })
              "
              outlined
              :menu-props="{
                bottom: true,
                offsetY: true
              }"
              validate-on-blur
              :rules="[v => !!v || $t('type_activity') + ' ' + $t('msgOblg')]"
            >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item v-bind="attrs" v-on="on">
                  <v-list-item-content>
                    <v-list-item-title class="title-list-item">
                      <div
                        class="line-color-item"
                        :style="'background-color :' + item.color"
                      ></div>
                      <div>
                        {{ item.designation }}
                      </div>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
            <!-- ASSIGNED TO -->
            <v-autocomplete
              :clearable="true"
              color="#5C2DD3"
              :placeholder="$t('assigned_to')"
              item-color="#5C2DD3"
              v-model="formDefault.user_id"
              :items="allUsers"
              :persistent-placeholder="true"
              :label="$t('assigned_to')"
              item-text="full_name"
              dense
              item-value="id"
              outlined
              :menu-props="{
                bottom: true,
                offsetY: true
              }"
              validate-on-blur
              :rules="[v => !!v || $t('assigned_to') + ' ' + $t('msgOblg')]"
            >
            </v-autocomplete>
            <!-- NOTES -->
            <v-textarea
              dense
              :label="$t('note') + 's'"
              v-model="formDefault.description"
              :persistent-placeholder="true"
              rows="3"
              outlined
              color="#5C2DD3"
            ></v-textarea>

            <!-- IMPORTE IMAGE / FILE -->
            <div class="container" @drop="handleFileInputAdd">
              <div class="file-wrapper">
                <input
                  type="file"
                  name="file-input"
                  id="uploadInput"
                  multiple
                  @change="handleFileInputAdd"
                />
                <div
                  class="upload_label"
                  :class="{
                    top:
                      formDefault &&
                      formDefault.files &&
                      formDefault.files.length >= 3
                  }"
                >
                  <font-awesome-icon
                    :icon="['far', 'file-arrow-up']"
                    class="icon-upload"
                  />
                  <p class="color-black mb-2">
                    <span class="drag-title"
                      ><u>
                        {{
                          $i18n.locale === 'fr'
                            ? 'Click pour uploader'
                            : 'Click to upload'
                        }}</u
                      ></span
                    >
                    {{
                      $i18n.locale === 'fr'
                        ? 'Ou glissez et déposer'
                        : 'or drag and drop'
                    }}
                  </p>
                  <p class=" mt-0 mb-0">SVG, PNG, JPG or GIF (max. 3MB)</p>
                </div>
                <div
                  class="upload_label"
                  :key="`container_${tableKey}`"
                  :class="{
                    top:
                      formDefault &&
                      formDefault.files &&
                      formDefault.files.length >= 3
                  }"
                >
                  <div
                    class="drag-title"
                    v-if="
                      formDefault &&
                        formDefault.files &&
                        formDefault.files.length
                    "
                  >
                    <div
                      class="drag-title-file"
                      v-for="(item, index) in Array.from(
                        formDefault.files
                      ).slice(0, 2)"
                      :key="index"
                    >
                      <div class="mt-1">{{ item.name }}</div>
                    </div>
                    <div v-if="formDefault.files.length > 2" class="mt-2">
                      ({{ formDefault.files.length - 2 }}
                      {{ $i18n.locale === 'fr' ? 'autres' : 'others' }})
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-form></template
        >
        <template #content-message>
          <div class="message">
            <div
              v-if="
                getEventLoading && (!errorMessages || !errorMessages.length)
              "
              class="loading"
            >
              {{ $t('loading') }}
            </div>
            <div v-if="hasError" class="error-msg">
              <ul v-if="Array.isArray(errorMessages)">
                <li v-for="(e, index) in errorMessages" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ errorMessages }}</span>
            </div>
          </div>
        </template>
        <template #content-actions>
          <v-btn
            color="#45148F"
            dark
            @click.prevent.stop="handlValidation"
            :loading="
              getEventLoading && (!errorMessages || !errorMessages.length)
            "
          >
            <span class="title-btn-edit-info mr-1 ml-1 font-sz-12">
              {{ typeCrud }}
            </span>
            <span><v-icon>mdi-chevron-right</v-icon></span>
          </v-btn>
          <v-btn
            text
            class="ml-3"
            @click.prevent.stop="handleClose('openModal')"
            variant="outline-secondary"
          >
            <span class="font-text bold-600 font-sz-12 color-black ">
              {{ $t('btnCancel') }}</span
            >
          </v-btn></template
        >
      </ModalRightDynamic>
    </portal>
  </div>
</template>
<script>
import ModalRightDynamic from '@/views/CRMILA26/Projects/Dialogs/ModalRightDynamic.vue'
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'modal-new-log-activity',
  props: {
    data: { required: true },
    labelModal: { required: true },
    typeCrud: { required: true },
    projet: {
      required: true
    }
  },
  components: {
    ModalRightDynamic
  },

  data() {
    return {
      errorMessages: null,
      objetDefault: {
        titre: null,
        date: null,
        type_event_id: null,
        user_id: null,
        description: null,
        files: []
      },
      tableKey: 0
    }
  },
  methods: {
    ...mapActions(['resetErrorEvent']),
    refreshComponentsFiles() {
      // Incrémenter la clé pour forcer la mise à jour du composant
      this.tableKey++
    },
    handleClose() {
      this.$emit('close')
      this.resetErrorEvent()
    },
    handlValidation() {
      const formData = new FormData()
      formData.append('date', this.formDefault.date)
      if (this.formDefault.description) {
        formData.append('description', this.formDefault.description)
      }
      formData.append('projet_id', this.projet.id)
      formData.append('titre', this.formDefault.titre)
      formData.append(
        'type_event_id',
        this.formDefault.type_event_id && this.formDefault.type_event_id.id
          ? this.formDefault.type_event_id.id
          : this.formDefault.type_event_id
      )
      formData.append(
        'user_id',
        this.formDefault.user_id && this.formDefault.user_id.id
          ? this.formDefault.user_id.id
          : this.formDefault.user_id
      )
      if (this.formDefault.files && this.formDefault.files.length) {
        for (let index = 0; index < this.formDefault.files.length; index++) {
          formData.append('files[]', this.formDefault.files[index])
        }
      }
      if (this.$refs.openModal.validate()) {
        this.$emit('valider', formData)
      }
    },
    handleOpenDateDebut(event) {
      if (!event) {
        this.$refs['date'].resetValidation()
      }
    },

    handleFileInputAdd(e) {
      this.formDefault.files =
        e && e.target && e.target.files ? e.target.files : []
      this.refreshComponentsFiles()
    }
  },
  computed: {
    ...mapGetters([
      'allUsers',
      'getTypeEventLoading',
      'getTypeEvent',
      'getEventError',
      'getEventLoading'
    ]),
    formDefault: function() {
      let objet = this.data ? this.data : this.objetDefault
      return objet
    },
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    }
  },
  watch: {
    getEventError(newValue) {
      this.errorMessages = newValue
    }
  },
  mounted() {}
}
</script>
<style lang="scss" scoped>
.line-color-item {
  width: 3px;
  height: 17px;
  border-radius: 9px;
}
.title-list-item {
  gap: 4px;
  display: flex;
  align-items: center;
}
// ----------------------------------- style image drag and drop -----------------------------------
.container {
  height: 230px;
  width: 100%;
  .file-wrapper {
    text-align: center;
    width: 105%;
    height: 100%;
    margin: 0px 0px 0px -12px;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    border: 1px dashed rgba(92, 45, 211, 1);
    background-color: transparent;
    border-radius: 5px;
    box-shadow: 1px 2px 5px 5px #f5f5f5;
    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
      z-index: 5;
    }
    .display {
      display: none;
    }
    .upload_label {
      font-size: 40px;
      position: relative;
      top: 32px;
      &.top {
        top: 12px;
      }
      .drag-title {
        text-decoration: underline;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        color: #704ad1;
      }
      .drag-title-file {
        text-decoration: underline;
        font-size: 12px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        color: #704ad1;
      }
      .color-black {
        color: #000;
      }
      p {
        font-size: 16px;
      }
      .icon-upload {
        width: 33px;
        height: 33px;
        margin-bottom: 15px;
        color: #704ad1;
      }
      .btn-upload-file {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        width: 60%;
        border-radius: 29px;
        background-color: #704ad1;
        padding: 11px 20px;
        border: 0;
        color: #fff;
        margin: auto;
      }
    }
  }
}
.drag-title-file {
  text-decoration: underline;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  color: #704ad1;
}
.overlay-modal {
  position: fixed;
  height: calc(100% - 0px);
  width: 100%;
  top: 0px;
  border: 0;
  background: #d6d6d64a;
  right: 0;
  left: 0;
  z-index: 101;
}
</style>
