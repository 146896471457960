<template>
  <div class="block-proposals">
    <!-- ---------------------------------------HEADER FILTER -------------------------------------- -->
    <TopMenu
      :label="$t('proposal')"
      @handlClickSwitch="handlClickSwitch"
      @handelDeleteMultiResource="handelDeleteMultiResource"
      @handleClickBtnAdd="handleClickBtnAdd"
      @showGrid="showGrid"
      @showList="showList"
      @refreshHeader="refreshHeader"
      :showActionHeaderEditable="showActionHeaderEditable"
      :selectcomputedTableBoardedCheck="selectedCheck"
      :selectedDefaultBtn="selectedDefaultBtn"
      :typeRessourceId="typeRessourceId"
      :switchLabel="
        !activeResource ? $t('proposal_disable') : $t('proposal_enable')
      "
      :showBtnAdd="true"
    ></TopMenu>
    <v-divider class="mt-2"></v-divider>
    <!-- ---------------------------------------TABLE -------------------------------------- -->
    <div class="content-block pt-2">
      <dataImmoTable
        v-if="selectedDefaultBtn == 'list'"
        :perPage="per_page"
        :key="`dataImmoTable_${tableKey}`"
        :Loader="getResourceLoading"
        :Total="resourceCount"
        :computedHeaderColumnTable="computedHeaderColumnTable"
        :computedTableBoard="computedTableBoard"
        :computedLastPage="computedLastPage"
        :pagePagination="page"
        @pagination="pagination"
        @SlectedItems="selectedMultiPropoasls"
        @openModalFileProjet="handleUpdateClick"
        :computedRestPage="computedRestPage"
        :styleComponents="'proposals'"
        :showActionHeaderEditable="showActionHeaderEditable"
      >
      </dataImmoTable>
      <GridFile
        v-else
        :key="coverFileKey"
        :styleComponents="'proposals'"
        :computedTableBoard="computedTableBoardByGrid"
        :total="resourceCount"
        :computedLastPage="computedLastPage"
        :pagePagination="page"
        @pagination="pagination"
        @deleted="handelDeleteResource"
        @edit="handleUpdateClick"
      ></GridFile>
    </div>
    <!-- ---------------------------------------MODAL ACTIONS -------------------------------------------------- -->
    <!-- AJOUTER -->
    <ModalProposal
      v-if="modalAdd"
      :data="null"
      :labelModal="$t('proposal')"
      :typeCrud="$t('btnAdd')"
      @pagination="paginationModal"
      :computedHeaderColumnTable="computedHeaderColumnTable"
      :totalPage="countPaginationAffecteResourceToProject"
      :computedLastPageModal="computedLastPageModal"
      @valider="handleValiderAddRessource"
      @validerAddResourceToProject="handleValiderAddRessourceToProject"
      @changeTypeProposals="changeTypeProposals"
      @changeFieldsTypeResource="changeFieldsTypeResource"
      @close="handleClose('modalAdd')"
    ></ModalProposal>
    <!-- Modification -->
    <ModalProposal
      v-if="modalEdit"
      :data="dataUpdate"
      :labelModal="$t('proposal')"
      :typeCrud="$t('update')"
      @valider="handleValiderUpdateRessource"
      @close="handleClose('modalEdit')"
      @removeFile="removeFile"
    ></ModalProposal>
    <!-- DELETED  -->
    <ModalDeleted
      v-if="modalDeleted"
      :label="$t('proposal')"
      @handleClose="handleClose('modalDeleted')"
      @handleDeleted="handleDeleted"
      :selectedCheck="selectedCheck"
      :loader="getResourceLoading"
    ></ModalDeleted>
  </div>
</template>
<script>
import GridFile from '@/views/CRMILA26/Projects/immobilier/pipline/Cover/GridFile.vue'
import dataImmoTable from '@/views/CRMILA26/Projects/immobilier/pipline/immoTable/dataImmoTable.vue'
import TopMenu from '@/views/CRMILA26/Projects/immobilier/pipline/Catalog/TopMenu.vue'
import ModalDeleted from '@/views/CRMILA26/Projects/immobilier/pipline/Catalog/ModalDeleted.vue'
import ModalProposal from './ModalProposal.vue'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    GridFile,
    dataImmoTable,
    TopMenu,
    ModalDeleted,
    ModalProposal
  },
  props: {
    projet: { required: false },
    dataProjet: { required: false }
  },
  data() {
    return {
      modalAdd: false,
      selectedDefaultBtn: 'list',
      typePropoasl: 1,
      showActionHeaderEditable: false,
      modalEdit: false,
      modalDeleted: false,
      affecterResourceToProjet: null,
      dataUpdate: null,
      proposalsToUpdate: null,
      loading: false,
      page: 1,
      per_page: 10,
      restPage: false,
      tableKey: 0,
      selectedCheck: null,
      openEdit: false,
      activeResource: 1,
      typeRessourceId: null,
      coverFileKey: 0
    }
  },
  methods: {
    ...mapActions([
      'fetchAllResource',
      'fetchAllTypeResource',
      'fetchOneTypeResource',
      'addResource',
      'resetErrorResource',
      'resetErrorTypeResource',
      'updateResourceProjet',
      'deletedMultiResource',
      'addResourceAffectedToProjet',
      'affecterNonResourceToProject',
      'removeResourceAffecteToProjet',
      'fetchHeaderResource',
      'fetchOneResource'
    ]),
    refreshDataImmoTable() {
      // Incrémenter la clé pour forcer la mise à jour du composant
      this.tableKey++
    },
    refreshCoverFile() {
      // Incrémenter la clé pour forcer la mise à jour du composant
      this.coverFileKey++
    },
    // UPDATE PROPOSALS
    async handleUpdateClick(item) {
      await this.fetchOneResource(item.id)
      this.dataUpdate = this.getOneResource
      this.modalEdit = true
    },
    async handleValiderUpdateRessource(formData, resource) {
      this.loading = true
      const response = await this.updateResourceProjet({
        body: formData,
        resource: resource
      })
      if (response) {
        this.handleClose('modalEdit')
      }
      this.loading = false
    },
    async removeFile(formData, resource) {
      await this.updateResourceProjet({
        body: formData,
        resource: resource
      })
    },
    // ADD PROPOSALS
    async changeTypeProposals(val) {
      if (val == 2) {
        await this.affecterNonResourceToProject({
          projet_id: this.projet.id,
          page: this.page,
          per_page: this.per_page
        })
        this.refreshDataImmoTable()
      }
      this.typePropoasl = val
      this.resetErrorResource()
      this.resetErrorTypeResource()
    },
    async changeFieldsTypeResource(field) {
      if (field) {
        await this.fetchOneTypeResource(field.id)
      }
    },
    handleClickBtnAdd() {
      this.modalAdd = true
    },
    handleClose(ref) {
      this[ref] = false
      if (ref == 'modalAdd') {
        this.typePropoasl = 1
      }
      this.handelFilter()
      this.refreshDataImmoTable()

      this.resetErrorResource()
      this.resetErrorTypeResource()
    },
    async handleValiderAddRessource(formData) {
      this.loading = true
      const response = await this.addResource(formData)
      if (response && response.success) {
        // affecter resource dans table prospasle
        const responseTwo = this.addResourceAffectedToProjet({
          projet_id: this.projet.id,
          ressources: [response.success.id]
        })
        if (responseTwo) {
          this.handleClose('modalAdd')
          this.showActionHeaderEditable = false
        }
      }
      this.loading = false
    },
    async handleValiderAddRessourceToProject(selectedCheckModal) {
      this.loading = true
      let affecterResourceToProjet = selectedCheckModal.map(item => {
        return item.id
      })
      const response = await this.addResourceAffectedToProjet({
        projet_id: this.projet.id,
        ressources: affecterResourceToProjet
      })
      if (response) {
        this.handleClose('modalAdd')
        this.showActionHeaderEditable = false
      }
      this.loading = false
      // }
    },
    // AFFICHAGE TABLE
    pagination(page) {
      this.page = page
      this.handelFilter()
    },
    paginationModal(page) {
      this.page = page
      this.affecterNonResourceToProject({
        page: this.page,
        per_page: this.per_page,
        projet_id: this.projet.id
      })
    },
    async handelFilter() {
      this.refreshHeader()
      await this.fetchAllResource({
        active: this.activeResource,
        page: this.page,
        per_page: this.per_page,
        projet_id: this.projet ? this.projet.id : null,
        refreshTable: false
      })
    },
    selectedMultiPropoasls(event) {
      this.showActionHeaderEditable = event.length > 0
      this.selectedCheck = event
    },
    showList() {
      this.selectedDefaultBtn = 'list'
      this.resetErrorResource()
    },
    showGrid() {
      this.selectedDefaultBtn = 'grid'
      this.resetErrorResource()
    },
    handelDeleteResource(proposal) {
      this.modalDeleted = true
      this.selectedCheck = [proposal]
    },
    handelDeleteMultiResource() {
      this.modalDeleted = true
    },
    async handleDeleted() {
      this.loading = true
      let affecterResourceToProjet = this.selectedCheck.map(item => {
        return item.id
      })
      const response = await this.removeResourceAffecteToProjet({
        projet_id: this.projet.id,
        ressources: affecterResourceToProjet
      })

      if (response) {
        this.page = 1
        this.handleClose('modalDeleted')
        this.refreshCoverFile()
        this.showActionHeaderEditable = false
      }
      this.loading = false
    },
    handlClickSwitch(active) {
      this.page = 1
      this.activeResource = active
      this.handelFilter()
    },
    async refreshHeader() {
      if (this.getTypeResource && this.getTypeResource.length) {
        this.typeRessourceId = this.getTypeResource[0].id
        await this.fetchHeaderResource({
          type_ressource_id: this.typeRessourceId
        })
      }
    },
    possibleValues(field) {
      let labels = '' // Initialisation d'une chaîne pour stocker les libellés
      // Diviser la chaîne en un tableau en utilisant la virgule comme délimiteur
      const valuesArray = field.value.split(',')
      // Parcourir chaque valeur dans le tableau
      valuesArray.forEach(value => {
        // Convertir la valeur en un nombre entier
        const intValue = parseInt(value.trim())
        // Rechercher la valeur dans les valeurs possibles
        const possibleValue = field.possible_values.find(
          item => item.id === intValue
        )
        // Si une correspondance est trouvée, ajouter le libellé à la chaîne
        if (possibleValue) {
          labels += possibleValue.label + ', '
        }
      })
      // Supprimer la virgule finale et l'espace
      labels = labels.slice(0, -2)
      return labels
    }
  },
  computed: {
    ...mapGetters([
      'getTypeResourceLoading',
      'getTypeResource',
      'getResourceLoading',
      'getResourceError',
      'getTypeResourceError',
      'countPaginationResource',
      'getResource',
      'countPaginationAffecteResourceToProject',
      'getHeadersResource',
      'resourceCount',
      'getOneResource'
    ]),
    computedRestPage() {
      return this.restPage
    },
    computedLastPage() {
      return Math.ceil(this.resourceCount / this.per_page)
    },
    computedLastPageModal() {
      return Math.ceil(
        this.countPaginationAffecteResourceToProject / this.per_page
      )
    },
    computedHeaderColumnTable: function() {
      if (this.getHeadersResource && this.getHeadersResource.length) {
        return this.getHeadersResource
          .map(i => {
            return {
              text: i.name,
              value: i.key,
              width: 170,
              ...i
            }
          })
          .filter(item => item.visibility)
      }
      return []
    },
    computedTableBoard: function() {
      if (this.getResource && this.getResource.length) {
        const formattedFields = this.getResource.flatMap(obj => {
          let newObj = {} // Utiliser let au lieu de const
          // Vérifier si l'objet contient la clé "fields" et si elle est définie
          if (Array.isArray(obj.fields)) {
            // Inclure seulement les champs spécifiques nommés "fields"
            obj.fields.forEach(field => {
              // Si le champ est de type select et a des valeurs possibles
              if (
                (field.input_type == 'multiple_select' ||
                  field.input_type == 'radio') &&
                Array.isArray(field.possible_values) &&
                field.value
              ) {
                newObj[field.key] = this.possibleValues(field)
                  ? this.possibleValues(field)
                  : '-'
              } else {
                // Si le champ n'est pas de type select ou n'a pas de valeurs possibles, utiliser la valeur existante
                newObj[field.key] = field.value ? field.value : '-'
              }
            })
          }
          return { ...obj, ...newObj } // Fusionner les champs spécifiques avec les autres propriétés
        })
        return formattedFields
      }
      return []
    },
    computedTableBoardByGrid: function() {
      if (this.getResource && this.getResource.length) {
        const newArray = this.getResource.map(obj => {
          let file_one = null
          if (obj.files && obj.files.length) {
            file_one = obj.files.find(item => item.cover === 1)
            if (!file_one) {
              file_one = obj.files[0]
            }
          }
          return {
            visible: false,
            file_one: file_one,
            type_show:
              obj.type && obj.type.designation
                ? obj.type.designation
                : obj.type,
            ...obj
          }
        })
        return newArray
      }

      return []
    }
  },
  watch: {
    selectedDefaultBtn(newValue, oldValue) {
      // Lorsque le bouton sélectionné change, rafraîchir le composant dataImmoTable
      if (newValue === 'list' && newValue !== oldValue) {
        this.refreshDataImmoTable()
      }
    }
  },
  async mounted() {
    // liste resource filtre par type de projet
    if (localStorage.getItem('typeProject')) {
      await this.fetchAllTypeResource({
        type_projet_id: localStorage.getItem('typeProject')
      })
    }
    this.handelFilter()
    this.refreshDataImmoTable()
  }
}
</script>
<style lang="scss" scoped>
.block-proposals {
  width: 100%;
  padding: 6px 16px 6px 8px;
  border-radius: 8px;
  background: linear-gradient(0deg, #ece8f4, #ece8f4),
    linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #ece8f4;
  background: #ffffff;
  cursor: pointer;
}
</style>
