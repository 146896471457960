<template>
  <div class="menu-header-history">
    <div class="title-task">
      {{ $t('history') }}
    </div>
    <!--  LOADING HISTORY -->
    <div class="message">
      <v-progress-circular
        v-if="loading"
        indeterminate
        class="sz-loader-moyen-icon"
        color="#3a167f"
      ></v-progress-circular>
    </div>
    <!-- PARTIE RIGHT -->
    <div class="flex-center">
      <!-- DROP DOWN FILTER HISTORY -->
      <div class="new-task mr-2">
        <div class="choisi-select-filter-immo">
          <v-menu
            :close-on-content-click="false"
            offset-y
            origin="center center"
            transition="scale-transition"
            :nudge-bottom="8"
            :nudge-left="6"
            :nudge-width="15"
            content-class="menu-history-activity"
          >
            <template v-slot:activator="{ attrs, on }">
              <div v-bind="attrs" v-on="on">
                <span class="label-btn">
                  {{
                    $i18n.locale === 'fr'
                      ? `Afficher : Toutes les activités`
                      : `Show : All Activities`
                  }}</span
                >
                <v-icon class="icon-flech">mdi-chevron-down</v-icon>
              </div>
            </template>
            <v-list class="list-type-projet-scroll">
              <div v-if="options && options.length">
                <v-list-item
                  class="list-type-projet-item"
                  v-for="item in options"
                  :key="item.label"
                  link
                >
                  <v-checkbox
                    hide-details=""
                    color="#5B2ECF"
                    :label="item.designation"
                    class="input-checkbox check-history-activity"
                    :value="item.id"
                    v-model="selectedActivity"
                    @change="handlFilterActivity"
                  ></v-checkbox>
                </v-list-item>
              </div>
              <v-list-item class="list-type-projet-item" v-else>
                <v-list-item-title
                  class=" list-type-onglet-projet block-flex-align-space-between"
                >
                  <span class="text-center mr-2 font-text font-sz-12 bold-700">
                    {{
                      $i18n.locale === 'fr' ? 'Aucune activité' : 'No activity'
                    }}
                  </span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <!-- ADD LOG ACTIVITY -->
      <div class="new-task">
        <v-btn
          dark
          class="btn-immo-outline"
          @click.prevent.stop="handelNewLogActivity"
        >
          <span class="label-btn"> {{ $t('log_activity') }}</span>
        </v-btn>
      </div>
      <ModalNewLogActivity
        v-if="newLogActivity"
        :data="null"
        :loading="getEventLoading"
        :typeCrud="$t('btnAdd')"
        :labelModal="$t('log_activity')"
        :projet="projet"
        @close="closeModal"
        @valider="valider"
      ></ModalNewLogActivity>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ModalNewLogActivity from './ModalNewLogActivity.vue'
export default {
  name: "menu-historique-activity",

  components: {
    ModalNewLogActivity
  },
  props: {
    loading: { default: false },
    options: { required: true },
    projet: { required: true }
  },
  data() {
    return { selectedActivity: [], newLogActivity: false }
  },
  computed: {
    ...mapGetters(['getTypeEvent', 'allUsers', 'getEventLoading'])
  },
  methods: {
    ...mapActions([
      'fetchALLTypeEventOfProject',
      'getAllUsers',
      'addLogActivity'
    ]),
    handlFilterActivity(filter) {
      this.$emit('handlFilterActivity', filter)
    },
    handelNewLogActivity() {
      this.newLogActivity = true
      // LIST TYPE ACTIVITY
      if (!this.getTypeEvent || !this.getTypeEvent.length) {
        this.fetchALLTypeEventOfProject({ project_id: this.projet.id })
      }
      // LIST USERS
      if (!this.allUsers || !this.allUsers.length) {
        this.getAllUsers()
      }
    },
    closeModal() {
      this.newLogActivity = false
    },
    async valider(body) {
      const reponse = await this.addLogActivity(body)
      if (reponse) {
        this.closeModal()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.menu-header-history {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .title-task {
    font-family: 'Montserrat', sans-serif;
    font-size: 13.5px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #45148f;
  }
  .menu-history-activity {
    padding: 0px 6px 0px 6px;
    background: white;
  }
  .list-type-projet-item {
    padding: 0 7px !important;
    border-radius: 4px;
    &:hover {
      background: #f0f4f8;
    }
  }
}
</style>
<style lang="scss">
.check-history-activity {
  width: 100% !important;
  .v-input--selection-controls__ripple {
    width: 0 !important;
    left: 0 !important;
    top: 0 !important;
  }
  .v-label {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #2f2f2f !important;
  }
}
.task-footer {
  .row-footer {
    background: #fff;
    margin: 0px;
    margin-top: 6px;
    .total-footer {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      color: #212121;
    }
    .pagination-footer {
      padding: 1px;
      .v-pagination {
        justify-content: flex-end;
      }
      .v-pagination__item,
      .v-pagination__navigation,
      .v-pagination__more {
        height: 28px;
        width: 28px;
        min-width: 28px;
        border-radius: 8px;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: center;
      }
      .v-pagination__navigation--disabled {
        background: #e6e6e6 !important;
        i {
          color: #b4b0c9 !important;
        }
      }
      .v-pagination__item,
      .v-pagination__more {
        color: #45148f;
        border: 1px solid #e9f6fa;
        background: #fff;
      }
      .v-pagination__more {
        align-items: normal;
        font-size: 19px !important;
        position: relative;
      }
      .v-pagination__more::after {
        content: '';
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 11px 0px 0px;
        border-color: transparent #45148f transparent transparent;
        border-radius: 5px;
      }
      .v-pagination__item--active {
        background-color: #45148f !important;
        color: #f2f2f2 !important;
        border: none !important;
      }
      .v-pagination__navigation {
        background: #45148f;
        color: #f2f2f2;
      }
      .v-pagination__navigation,
      .v-pagination__item--active {
        border: none;
        i {
          color: #f2f2f2;
        }
      }
    }
  }
}
</style>
