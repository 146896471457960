<!--************ NB : il existe une condition d'affichage (Draggable et non draggable) l'activation et la disactivation à partir de button switch ************* -->
<template>
  <div class="info-component">
    <div
      class="info-content"
      v-if="chunkedCategories && chunkedCategories.length"
    >
      <div
        class="info-row"
        v-for="(category, indexRow) in chunkedCategories"
        :key="'row-' + indexRow + '-' + componentKey"
      >
        <div
          class="info-category-card"
          v-for="item in category"
          :key="'cat-info' + item.id"
        >
          <div style="display: flex; align-items: center;">
            <!-- Nom de champs  -->
            <div class="info-category-card-title pb-2*">{{ item.name }}</div>
          </div>
          <div
            class="info-category-card-content"
            v-if="
              item.subcategories &&
                item.subcategories.length &&
                item.fields &&
                item.fields.length
            "
          >
            <Container
              :drop-placeholder="dropPlaceholderOptions"
              :lock-axis="'y'"
              @drop="onColumnDropProjects($event, item)"
              drag-handle-selector=".column-drag-container"
            >
              <!------------ Contenu des champs draggable ------------>
              <template v-if="dragAndDropEnabled">
                <Draggable
                  v-for="field in item.fields"
                  :key="'field' + field.id"
                  class="fields-visible-info"
                  :class="{
                    'field-check': field.input_type == 'radio' && !field.type
                  }"
                >
                  <div v-if="field.object && field.object == 'projets'">
                    <EditableCustomFieldCategorieInfoImmo
                      :dragAndDropEnabled="dragAndDropEnabled"
                      :key="`componenets_edit${tableKey}`"
                      :readonly="false"
                      :field="field"
                      :entity="getProjectsExtraProject"
                      :value="field.value"
                      :subCategory="item.fields"
                      :category="item"
                      @updateFunction="updateFieldsBlock"
                      @disabledBtnSave="disabledBtnSave"
                      :fieldKeyEdit="false"
                      :dragAndDropField="false"
                    />
                  </div>
                  <div v-else>
                    <EditableCustomFieldCategorieInfoImmo
                      :dragAndDropEnabled="dragAndDropEnabled"
                      :key="`componenets_edit${tableKey}`"
                      :readonly="false"
                      :field="field"
                      :subCategory="item.fields"
                      :category="item"
                      :entity="getProjectsExtraProject"
                      :value="field.value"
                      @updateFunction="updateFieldsBlock"
                      @disabledBtnSave="disabledBtnSave"
                      :fieldKeyEdit="false"
                      :dragAndDropField="false"
                    />
                  </div>
                </Draggable>
              </template>

              <!----------- Contenue des champs non draggable -------------->
              <!-- Contenue des champs lorsque le drag-and-drop est désactivé -->
              <template v-else>
                <div
                  v-for="field in item.fields"
                  :key="'field' + field.id"
                  class="fields-visible-info"
                >
                  <div
                    v-if="field.object && field.object == 'projets'"
                    class="no-drag-icon"
                  >
                    <EditableCustomFieldCategorieInfoImmo
                      :dragAndDropEnabled="dragAndDropEnabled"
                      :key="`componenets_edit${tableKey}`"
                      :readonly="false"
                      :field="field"
                      :entity="getProjectsExtraProject"
                      :value="field.value"
                      :subCategory="item.fields"
                      :category="item"
                      @updateFunction="updateFieldsBlock"
                      @disabledBtnSave="disabledBtnSave"
                      :fieldKeyEdit="false"
                      :dragAndDropField="false"
                    />
                  </div>
                  <div v-else>
                    <EditableCustomFieldCategorieInfoImmo
                      :dragAndDropEnabled="dragAndDropEnabled"
                      :key="`componenets_edit${tableKey}`"
                      :readonly="false"
                      :field="field"
                      :subCategory="item.fields"
                      :category="item"
                      :entity="getProjectsExtraProject"
                      :value="field.value"
                      @updateFunction="updateFieldsBlock"
                      @disabledBtnSave="disabledBtnSave"
                      :fieldKeyEdit="false"
                      :dragAndDropField="false"
                    />
                  </div>
                </div>
              </template>
            </Container>
          </div>
          <template v-else>
            <div
              v-if="!item.subcategories || !item.subcategories.length"
              class="div-aucun immo-catg"
            >
              <div class="titre immo-catg">
                {{
                  $i18n.locale === 'fr'
                    ? "Il n'y a pas de sous-catégories pour cette catégorie"
                    : 'There are no sub-categories for this category'
                }}
              </div>
            </div>
            <div
              v-if="
                (!item.fields || !item.fields.length) &&
                  item.subcategories &&
                  item.subcategories.length
              "
              class="div-aucun immo-catg"
            >
              <div class="titre immo-catg">
                {{
                  $i18n.locale === 'fr'
                    ? 'Aucun champ disponible pour cette catégorie'
                    : 'No fields available for this category'
                }}
              </div>
            </div>
          </template>

          <div class="float-right mb-0">
            <v-btn
              color="#45148F"
              dark
              small
              @click.prevent.stop="showDetailsModal(item)"
              class="label-btn-form-immo sz-btn-detail mr-1 mb-2"
              style="height: 30px;"
            >
              <v-icon class="font-sz-16 mr-1">mdi-plus</v-icon>
              <span class="font-sz-12" style="font-weight: normal;"
                >{{ $t('btnDetail') }}
              </span>
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <!-- **************************************** START *************************************************** -->
    <!-- OPEN MODAL ADD INFORMATION -->
    <div class="overlay-modal" v-if="modalDetails">
      <portal to="new-project-modal">
        <ModalRightDynamic @closeModal="handleClose('modalDetails')">
          <template #title>
            {{
              selectedSubCategory && $i18n.locale === 'fr'
                ? `${selectedSubCategory.name} ${$t('btnDetail')}`
                : `${selectedSubCategory.name} ${$t('btnDetail')}`
            }}
          </template>
          <template #content-form>
            <v-form ref="modalDetails">
              <div class="info-category-card-content">
                <div
                  v-for="(subCat, indexS) in selectedSubCategory.subcategories"
                  :key="'subCat-modal' + indexS"
                >
                  <div class="info-sub-category-card-title mb-3">
                    {{ subCat.name }}
                  </div>
                  <div
                    v-if="subCat.fields && subCat.fields.length"
                    class="subCat-fields"
                  >
                    <div
                      v-for="(field, indexF) in subCat.fields"
                      :key="'field-modal' + indexF"
                      class="input-field flex-center"
                    >
                      <div
                        v-if="field.object && field.object == 'projets'"
                        class="width-100"
                      >
                        <EditableCustomFieldCategorieInfoImmo
                          :readonly="false"
                          :field="field"
                          :entity="getProjectsExtraProject"
                          :value="field.value ? field.value : field.lastValue"
                          :oneSubCat="subCat"
                          :subCategory="selectedSubCategory.subcategories"
                          :category="selectedSubCategory"
                          @updateFunction="updateFieldsForm"
                          @disabledBtnSave="disabledBtnSaveModalDetail"
                          :fieldKeyEdit="false"
                          :dragAndDropField="true"
                        />
                      </div>
                      <div v-else class="width-100">
                        <EditableCustomFieldCategorieInfoImmo
                          :readonly="false"
                          :field="field"
                          :oneSubCat="subCat"
                          :subCategory="selectedSubCategory.subcategories"
                          :category="selectedSubCategory"
                          :entity="getProjectsExtraProject"
                          :value="field.value ? field.value : field.lastValue"
                          @updateFunction="updateFieldsForm"
                          @disabledBtnSave="disabledBtnSaveModalDetail"
                          :fieldKeyEdit="false"
                          :dragAndDropField="true"
                        />
                      </div>
                      <v-checkbox
                        class="input-checkbox check-visible-imo-modal mb-1"
                        color="#5C2DD3"
                        hide-details
                        v-model="field.visibility"
                        @change="
                          visibilityFields(field, selectedSubCategory, subCat)
                        "
                      >
                      </v-checkbox>
                    </div>
                  </div>
                  <div v-else class="div-aucun immo-modal">
                    <div class="titre immo-catg">
                      {{
                        $i18n.locale === 'fr'
                          ? 'Aucun champ disponible'
                          : 'No fields available'
                      }}
                    </div>
                  </div>
                  <v-divider
                    class="pb-3"
                    v-if="
                      selectedSubCategory.subcategories.length - indexS >=
                        indexS
                    "
                  ></v-divider>
                </div></div></v-form
          ></template>
          <template #content-message>
            <div class="message">
              <div
                v-if="
                  (getProjectsProcessing || loadingVisibiltyCheck) &&
                    (!errorMessages || !errorMessages.length)
                "
                class="loading"
              >
                {{ $t('loading') }}
              </div>
              <div v-if="hasError" class="error-msg">
                <ul v-if="Array.isArray(errorMessages)">
                  <li v-for="(e, index) in errorMessages" :key="index">
                    {{ e }}
                  </li>
                </ul>
                <span v-else>{{ errorMessages }}</span>
              </div>
            </div>
          </template>
          <template #content-actions>
            <v-btn
              :loading="
                getProjectsProcessing &&
                  (!errorMessages || !errorMessages.length)
              "
              color="#45148F"
              dark
              :class="{ 'custom-disabled-btn': disabledBtn }"
              @click.prevent.stop="editFormInformation"
            >
              <span class="title-btn-edit-info">
                {{ $t('update') }}
                {{
                  selectedSubCategory ? `${selectedSubCategory.name}` : ''
                }}</span
              >
              <span><v-icon>mdi-chevron-right</v-icon></span>
            </v-btn>
            <v-btn
              text
              class="ml-3"
              @click.prevent.stop="handleClose('modalDetails')"
              variant="outline-secondary"
            >
              <span class="font-text bold-600 font-sz-12 color-black">
                {{ $t('btnCancel') }}</span
              >
            </v-btn></template
          >
        </ModalRightDynamic>
      </portal>
    </div>
    <!-- CLOSE MODAL ADD INFORMATION  ------>
    <!-- **************************************** END *************************************************** -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EditableCustomFieldCategorieInfoImmo from '@/components/CrmIla26Component/EditableCustomFieldCategorieInfoImmo.vue'
import ModalRightDynamic from '@/views/CRMILA26/Projects/Dialogs/ModalRightDynamic.vue'
import { Container, Draggable } from 'vue-smooth-dnd'

export default {
  props: {
    dragAndDropEnabled: { required: true }
  },
  data() {
    return {
      modalDetails: false,
      selectedSubCategory: null,
      errorMessages: null,
      bodyUpdateFields: null,
      bodyUpdateFieldsBlock: null,
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      tableKey: 0,
      disabledBtn: true,
      loadingVisibiltyCheck: false,
      componentKey: 0
    }
  },
  components: {
    EditableCustomFieldCategorieInfoImmo,
    ModalRightDynamic,
    Container,
    Draggable
  },
  computed: {
    ...mapGetters([
      'getProjectsExtraProject',
      'getProjectsProcessing',
      'getProjectsError'
    ]),
    chunkedCategories: function() {
      // Chunk categories into groups of 2 for each row
      const chunkSize = this.calculateChunkSize()
      const chunked = []
      if (
        this.getProjectsExtraProject &&
        this.getProjectsExtraProject.categoriesProjets &&
        this.getProjectsExtraProject.categoriesProjets.length
      ) {
        for (
          let i = 0;
          i < this.getProjectsExtraProject.categoriesProjets.length;
          i += chunkSize
        ) {
          chunked.push(
            this.getProjectsExtraProject.categoriesProjets.slice(
              i,
              i + chunkSize
            )
          )
        }
      }
      return chunked
    },

    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    }
  },
  methods: {
    ...mapActions([
      'updateProject',
      'visibilityAndUpdateOrderFieldsToCategory',
      'refrechProject',
      'updateOrderFieldsCategory',
      'reafreshContactData',
      'resetErrorProject'
    ]),
    refreshComponent() {
      this.componentKey++ // Modifier la clé pour actualiser le composant
    },
    refreshDataImmoComponents() {
      // Incrémenter la clé pour forcer la mise à jour du composant
      this.tableKey++
    },
    // FUNCTION TO TEST IF THERE IS A CHANGE IN THE FIELD
    async disabledBtnSave() {
      await this.editBlockInformation()
      if (!this.modalDetails) {
        this.$emit('refreshEditInputInformation')
      }
    },
    disabledBtnSaveModalDetail() {
      this.disabledBtn = false
    },
    updateFieldsBlock(payload) {
      this.bodyUpdateFieldsBlock = payload
    },
    async editBlockInformation() {
      await this.updateProject(this.bodyUpdateFieldsBlock)
      this.$nextTick(() => {
        if (!this.errorMessages || !this.errorMessages.length) {
          if (
            this.bodyUpdateFieldsBlock.field ==
            '492a75ea3be54af620c29c48e39f9522ac4ff6ec4db3de6647'
          ) {
            this.reafreshContactData(this.getProjectsExtraProject)
          }
          this.refrechProject({
            project: this.getProjectsExtraProject,
            reset: 1,
            notRefreshHistory: true
          })
        }
      })
    },
    async visibilityFields(field, category, oneSubCat) {
      this.loadingVisibiltyCheck = true
      let oneSubCatExists = category.subcategories.includes(oneSubCat)
      if (oneSubCatExists) {
        let fieldsArray = category.subcategories.reduce(
          (accumulator, currentValue) => {
            // Filtrer les champs avec la visibilité égale à 1
            let visibleFields = currentValue.fields.filter(
              field => field.visibility == 1
            )
            // Mapper les champs filtrés dans le format souhaité
            let mappedFields = visibleFields.map(field => ({
              id: field.id,
              visibility: field.visibility ? 1 : 0
            }))
            return accumulator.concat(mappedFields)
          },
          []
        )
        await this.visibilityAndUpdateOrderFieldsToCategory({
          category_id: category.id,
          fields: fieldsArray
        })
      }
      this.loadingVisibiltyCheck = false
    },
    onColumnDropProjects(dropResult, category) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      let orderCategory = this.applyDrag(category.fields, dropResult)
      this.updateOrderFieldsCategory({ fields: orderCategory, category })
      this.visibilityAndUpdateOrderFieldsToCategory({
        category_id: category.id,
        fields: orderCategory.map(field => ({
          id: field.id,
          visibility: 1
        }))
      })
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    },
    calculateChunkSize() {
      // Calculez la taille du chunk en fonction de la largeur de l'écran
      const screenWidth = window.innerWidth
      if (screenWidth >= 1200) {
        return 8 // Afficher 4 blocs par ligne pour les grands écrans
      } else if (screenWidth >= 992) {
        return 3 // Afficher 3 blocs par ligne pour les écrans de taille moyenne
      } else {
        return 2 // Afficher 2 blocs par ligne pour les petits écrans
      }
    },
    showDetailsModal(subCategory) {
      this.resetErrorProject()
      this.selectedSubCategory = { ...subCategory }
      this.disabledBtn = true
      this.modalDetails = true
    },
    handleClose(ref) {
      this.refrechProject({
        project: this.getProjectsExtraProject,
        reset: 1,
        notRefreshHistory: true
      })
      this[ref] = false
      this.resetErrorProject()
    },
    updateFieldsForm(payload) {
      this.bodyUpdateFields = payload
    },
    async editFormInformation() {
      if (
        this.bodyUpdateFields &&
        this.bodyUpdateFields.fieldProjet &&
        this.bodyUpdateFields.fieldProjet.length
      ) {
        await this.updateProject({
          entity: this.bodyUpdateFields.entity,
          field: this.bodyUpdateFields.fieldProjet
        })
      }
      this.$nextTick(() => {
        if (!this.errorMessages || !this.errorMessages.length) {
          this.handleClose('modalDetails')
          this.refreshDataImmoComponents()
        }
      })
    }
  },
  watch: {
    $route(route) {
      if (route.name == 'project' && route.params.id) {
        this.refreshComponent()
        this.refreshDataImmoComponents()
      }
    },
    getProjectsExtraProject() {
      this.refreshComponent()
      this.refreshDataImmoComponents()
    },
    getProjectsError(newValue) {
      this.errorMessages = newValue
    }
  },
  async mounted() {
    await this.refrechProject({
      project: this.getProjectsExtraProject,
      reset: 1,
      notRefreshHistory: true
    })
  }
}
</script>

<style lang="scss" scoped>
.info-component {
  width: 100%;
  // max-height: calc(100vh - 360px);
  // height: calc(100vh - 360px);
  // overflow-y: auto;
  .info-content {
    display: flex;
    width: 100%;
    .info-category-card {
      // width: 50%;
      // height: 272px;
      // height: 550px;

      margin-right: 8px;
      margin-bottom: 10px;
      padding: 9px 11px 3px 10px;
      // padding: 16px 16px 16px 10px;
      border-radius: 8px;

      background: linear-gradient(0deg, #ece8f4, #ece8f4),
        linear-gradient(0deg, #ffffff, #ffffff);
      border: 1px solid #ece8f4;
      background: #ffffff;

      // height: calc(100vh - 374px);
      // overflow: auto;
      .info-category-card-title {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 600;
        line-height: 20.8px;
        letter-spacing: 0em;
        overflow: hidden;
        // width: 44vh;
        text-overflow: ellipsis;
        color: #45148f;
        white-space: nowrap;
        margin-bottom: 9px;
        margin-left: 10px;
      }
      .info-category-card-title::first-letter {
        text-transform: uppercase;
      }
      .info-category-card-content {
        position: relative;
        left: -3px;
        overflow-y: auto;
        padding-top: 4px;
        margin-bottom: 6px;
        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px #f0eef8;
          border-radius: 4px;
          background-color: rgba(238, 238, 238, 1) !important;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: #45148f;
        }
        .smooth-dnd-draggable-wrapper {
          overflow: initial !important;
          display: block !important;
        }
        .fields-visible-info {
          padding-bottom: 7px;
          &.field-check {
            padding-bottom: 9px !important;
          }
        }
      }
      .info-category-card-content {
        // height: 188px;
        height: calc(100vh - 446px);
      }
    }
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #bdbdbd;
    cursor: pointer !important;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: 0px 1px 0px 0px #0000001f inset;
    box-shadow: 0px 1px 0px 0px #0000001f inset;
    border-radius: 4px;
    background-color: #eeeeee;
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 5px;
  }
}
//
.info-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.info-category-card {
  /* width: calc(33.333% - 10px); */
  width: calc(49.333% - 10px);
  margin-right: 8px;
  margin-bottom: 10px;
}

.input-field {
  margin-bottom: 11px;
}

//
@media (max-width: 992px) {
  .info-category-card {
    width: calc(50% - 10px);
  }
}

@media (max-width: 576px) {
  .info-category-card {
    width: calc(100% - 10px);
  }
}

/* Pour ajuster la largeur des blocs dans la dernière ligne */
.info-row:last-child .info-category-card {
  width: 33%;
  margin-right: 3px;
  // &.margin-thre-cat {
  //   margin-right: 3px;
  // }
}
//
</style>
<style lang="scss" scoped>
// ----------------------------------- style image drag and drop -----------------------------------
.container {
  height: 230px;
  width: 100%;
  .file-wrapper {
    text-align: center;
    width: 105%;
    height: 90%;
    margin: 0px 0px 0px -12px;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    border: 1px dashed rgba(92, 45, 211, 1);
    background-color: transparent;
    border-radius: 5px;
    box-shadow: 1px 2px 5px 5px #f5f5f5;
    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
      z-index: 5;
    }
    .display {
      display: none;
    }
    .upload_label {
      font-size: 40px;
      position: relative;
      top: 32px;
      .drag-title {
        text-decoration: underline;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
        font-weight: 400;
        color: #704ad1;
      }
      .color-black {
        color: #000;
      }
      p {
        font-size: 16px;
      }
      .icon-upload {
        width: 33px;
        height: 33px;
        margin-bottom: 15px;
        color: #704ad1;
      }
      .btn-upload-file {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        font-weight: 400;
        width: 60%;
        border-radius: 29px;
        background-color: #704ad1;
        padding: 11px 20px;
        border: 0;
        color: #fff;
        margin: auto;
      }
    }
  }
}
.overlay-modal {
  position: fixed;
  height: calc(100% - 0px);
  width: 100%;
  top: 0px;
  border: 0;
  background: #d6d6d64a;
  right: 0;
  left: 0;
  z-index: 101;
}
.info-sub-category-card-title {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  font-size: 14px;
  line-height: 17.07px;
  font-family: 'Montserrat', sans-serif;
  text-overflow: ellipsis;
  /* width: 47vh; */
  overflow: hidden;
  white-space: nowrap;
  padding-bottom: 10px;
}
.info-sub-category-card-title {
  text-transform: capitalize;
}
// .subCat-fields {
//   // margin-left: -9px;
// }
.overlay-modal-wrapper {
  position: fixed;
  width: 445px;
  right: 0px;
  height: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.32);
  font-size: 13px;
  color: #5e5e5e;
}
</style>
<style lang="scss">
.no-drag-icon.smooth-dnd-draggable-wrapper {
  display: none; /* Masquer les icônes de glisser-déposer lorsque le glisser-déposer est désactivé */
}
.sz-btn-detail {
  .v-btn:not(.v-btn--round).v-size--small {
    height: 30px !important;
  }
}
</style>
