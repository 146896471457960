<template>
  <!-- OPEN MODAL DELETED PROPOSALS MULTI -->
  <v-dialog max-width="500px" scrollable :value="true">
    <v-card>
      <v-card-title class="text-h6 title-modal">
        <v-label class="label-header-card-immo">
          {{ $t('deleted') }} {{ label }}
        </v-label>
        <v-btn
          class="btn-close-header"
          icon
          @click="handleClose"
          :title="$t('close')"
          color="#45148F"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="body-card center-text  mt-2">
        <div class="mb-3 color-black-light">
          {{
            $t('msgdeleted', {
              msg: $i18n.locale === 'fr' ? `le(s) ${label}` : `the ${label}`
            })
          }}
          <strong
            class="text-capitalize"
            v-if="selectedCheck && selectedCheck.length == 1"
          >
            {{
              selectedCheck && selectedCheck.length
                ? selectedCheck[0].designation
                : ''
            }}
          </strong>
          ?
          <ul v-if="selectedCheck && selectedCheck.length > 1" class="mt-2">
            <li
              v-for="item in selectedCheck"
              :key="item.id"
              class="text-capitalize mb-2"
            >
              {{ item.designation }}
            </li>
          </ul>
        </div>
        <div class="message pr-3">
          <div v-if="hasError" class="error-msg">
            <ul v-if="Array.isArray(errorMessages)">
              <li v-for="(e, index) in errorMessages" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ errorMessages }}</span>
          </div>
        </div>
      </v-card-text>
      <!-- <v-divider class="line-hr-footer"></v-divider> -->
      <v-card-actions class="actions-msg-btn-modal">
        <v-btn
          color="#E02D17"
          dark
          @click.prevent.stop="handleDeleted"
          :loading="loader"
        >
          <span class="label-btn-form-immo pl-2 pr-2">
            {{ $t('deleted') }}</span
          >
        </v-btn>
        <v-btn text @click="handleClose" :disabled="loader">{{
          $t('btnCancel')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- CLOSE MODAL DELETED PROPOSALS MULTI -->
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    selectedCheck: { required: true },
    label: { required: true },
    loader: { required: true }
  },
  data() {
    return { errorMessages: null }
  },
  methods: {
    handleClose() {
      this.$emit('handleClose')
    },
    handleDeleted() {
      this.$emit('handleDeleted')
    }
  },
  computed: {
    ...mapGetters(['getResourceError']),
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    }
  },
  watch: {
    getResourceError(newValue) {
      this.errorMessages = newValue
    }
  }
}
</script>
<style scoped>
.label-header-card-immo {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0px;
  color: #45148f !important;
}
</style>
