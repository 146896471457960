<template>
  <div class="file-project-immo">
    <div class="block-file first-block">
      <!-- PIPLINE BACK  -->
      <div class="back-class" @click="$emit('closeFileEvent')">
        <font-awesome-icon :icon="['fas', 'arrow-left']" /> Back
      </div>
      <!-- PIPLINE MENU TASKS  -->
      <menu-task
        :options="computedMenuAction"
        @clickItem="handelEventAction"
      ></menu-task>
      <asign
        :value="getProjectsExtraProject?.project_owner"
        :updateFunction="assignProject"
        :options="allUsers"
      >
      </asign>
    </div>
    <div class="block-file second-block">
      <!-- PIPLINE DATA BLOCK  -->
      <div class="info-block">
        <div>
          <div class="title-block">
            {{
              computedFullName && computedFullName.value
                ? computedFullName.value
                : '-'
            }}
          </div>
          <div class="details-block">
            {{ $t('project_id') }} Id :
            {{
              getProjectsExtraProject && getProjectsExtraProject.opportunity_id
                ? getProjectsExtraProject.opportunity_id
                : ''
            }}
          </div>
        </div>
        <div class="left-side">
          <div class="border-r">
            <div class="title-block">
              {{
                computedFormatNumber(
                  getProjectsExtraProject?.header_field?.value
                )
              }}
              {{
                getProjectsExtraProject?.header_field?.value
                  ? getProjectsExtraProject.symbol_devise
                  : ''
              }}
            </div>
            <div class="details-block">
              {{ getProjectsExtraProject?.header_field?.name }}
            </div>
          </div>
          <div>
            <div class="title-block">
              {{
                getProjectsExtraProject?.project_owner
                  ? getProjectsExtraProject.project_owner.first_name +
                    ' ' +
                    getProjectsExtraProject.project_owner.last_name
                  : '-'
              }}
            </div>
            <div class="details-block">{{ $t('owner') }}</div>
          </div>
        </div>
      </div>
      <!-- PIPLINE PROGRESS BLOCK  -->

      <div class="stade-block">
        <div>
          <div class="title-block">{{ $t('progress') }}</div>
          <div class="details-block">
            {{ $t('active_since') }} :
            {{
              getProjectsExtraProject && getProjectsExtraProject.created_at
                ? computedFormatDate(getProjectsExtraProject.created_at)
                : ''
            }}
          </div>
        </div>
        <div class="stepper-block">
          <stepper :options="computedOptionsStep"></stepper>
        </div>
      </div>
      <!-- PIPLINE MENU TABS -->
      <div class="menu-block">
        <v-tabs
          :hide-slider="false"
          slider-color="#5C2DD3"
          color="#5C2DD3"
          item-color="#5C2DD3"
          v-model="menuFile"
        >
          <v-tab
            v-for="(item, index) in menuProject"
            :key="index"
            :href="`#tab-${index}`"
            color="#5C2DD3"
            item-color="#5C2DD3"
            class="tab-menu-block"
          >
            <!--------------- Nom de composant   --------------->
            <span @click="toggleTabs">{{ $t(item.key) }} </span>

            <!---------------- Button pour activer ou disactiver le mode drag and drop  -->
            <button
              v-if="item.key === 'information'"
              @click="toggleDragAndDrop"
              style="margin-left: auto; margin-left: 10px; margin-bottom: 6px;"
              title="Lock ou unlock"
            >
              <!--Switch font awesome  -->
              <font-awesome-icon
                :icon="[
                  'far',
                  dragAndDropEnabled ? 'lock-keyhole-open' : 'lock-keyhole'
                ]"
                style="color: #45148f; font-size: 16px;"
              />
            </button>
          </v-tab>
        </v-tabs>
      </div>
    </div>
    <!-- PIPLINE  TABS   -->
    <div class="third-block">
      <!-- ACTIVITIES TAB  -->
      <template v-if="menuFile == 'tab-0'">
        <Activity
          :projet="data"
          :options="getTypeEvent"
          @newTask="handelEventTask"
          @updateTask="handelEventUpdateTask"
        />
      </template>
      <!-- ONFORMATION TAB  -->
      <template v-if="menuFile == 'tab-1'">
        <information
          :dragAndDropEnabled="dragAndDropEnabled"
          @refreshEditInputInformation="refreshEditInputInformation"
        ></information>
      </template>
      <!-- PROPOSAL TAB  -->
      <template v-if="menuFile == 'tab-2'">
        <Proposals
          :projet="data"
          :dataProjet="getProjectsExtraProject"
          componentsInterface="proposals"
        />
      </template>
      <!-- DOCUMENT TAB  -->
      <template v-if="menuFile == 'tab-3'">
        <documents :projet="data"></documents>
      </template>
    </div>

    <!-- UPDATE STAGE MODAL  -->
    <portal to="portal-modal-immobilier">
      <updateStage
        v-if="updateProjectState"
        @close="closeModal('stage')"
        :stateToUpdate="stateToUpdate"
        :validateFunction="handelUpdateStage"
      />
      <task
        v-if="newTaskModal"
        @close="closeModal('task')"
        :task="newTask"
        :validateFunction="handelNewTask"
        :disabledType="disabledType"
        :title="titleModalTask"
      />
      <action-msg
        v-if="openModalAcion"
        @close="closeModal('action')"
        :actionEvent="newAction"
        :title="titleModalTask"
        :validateFunction="handelValidateAction"
        :fields="fieldsAction"
      ></action-msg>
    </portal>

    <!-- ----------------------- ALERT PROJET -------------------------------------------- -->
    <div class="message width-100" v-if="loadingUpdateBlock">
      <Alert :message="errorMessages" :typeError="hasError"></Alert>
    </div>
    <!-- ----------------------- ALERT ACTION -------------------------------------------- -->
    <div class="message width-100" v-if="showAlert">
      <AlertAction :message="alertMessage" :type="alertType"></AlertAction>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import Activity from '../Activity/Activity.vue'
import information from './component/information.vue'
import stepper from './component/stepper.vue'
import Proposals from '@/views/CRMILA26/Projects/immobilier/pipline/Proposals/Proposals.vue'
import documents from './component/documents.vue'
import menuTask from './component/menuTask.vue'
import Asign from './component/asign.vue'
import Alert from '@/components/CrmIla26Component/Alert.vue'
import AlertAction from '@/components/CrmIla26Component/AlertAction.vue'
import currencySymbols from '@/currency/currency.json'
export default {
  components: {
    Activity,
    information,
    updateStage: () => import('./component/updateStage.vue'),
    task: () => import('./component/task.vue'),
    stepper,
    Proposals,
    documents,
    menuTask,
    Asign,
    Alert,
    AlertAction,
    actionMsg: () => import('./component/action/actionMsg.vue')
  },
  props: {
    data: { required: true },
    readonly: { default: false }
  },
  data() {
    return {
      menuProject: [
        {
          key: 'activities'
        },
        {
          key: 'information'
        },
        {
          key: 'proposals'
        },
        {
          key: 'documents'
        }
      ],
      menuFile: 'tab-0',
      updateProjectState: false,
      stateToUpdate: null,
      newTaskModal: false,
      newTask: null,
      disabledType: true,
      titleModalTask: 'add_new_task',
      loadingUpdateBlock: false,
      errorMessages: null,
      openModalAcion: false,
      newAction: null,
      fieldsAction: [],
      alertMessage: null,
      alertType: 'success',
      showAlert: false,
      dragAndDropEnabled: true
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsExtraProject',
      'getEtapes',
      'getTypeEvent',
      'getIconFormate',
      'getEventError',
      'allUsers',
      'getProjectsError',
      'getContactsError',
      'getCurentProjectType'
    ]),
    computedFormatNumber() {
      return function(number) {
        if (number) {
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        }
      }
    },
    computedFullName() {
      return this.computedCustomField(
        '492a75ea3be54af620c29c48e39f9522ac4ff6ec4db3de6647'
      )
    },
    computedCurrency() {
      let currency = this.computedCustomField(
        '645c6e4aeeaea76000d83beb8417614ddf1467cb2e0ae5a9ad'
      )
      return currency.value ? this.getCurrencySymbol(currency.value) : ''
    },
    computedCustomField() {
      return function(key) {
        let foundField = null
        this.getProjectsExtraProject?.categoriesProjets.some(category => {
          foundField = category.fields.find(field => field.key === key)
          return foundField !== undefined
        })
        return foundField
      }
    },
    computedMenuAction() {
      return [
        { designation: this.$t('message'), key: 'message', icon: 'far phone' },
        { designation: this.$t('email'), key: 'email', icon: 'far envelope' },
        { designation: this.$t('task'), key: 'task', icon: 'far file-check' }
      ]
    },
    computedFormatDate() {
      return function(date) {
        return moment(date).format('DD MMMM YYYY')
      }
    },
    computedStep() {
      let step = this.getEtapes.filter(
        i => i.id == this.getProjectsExtraProject?.pipeline?.id
      )
      if (step.length) return step[0].stages
      return []
    },
    computedOptionsStep() {
      return this.computedStep.map((item, index) => {
        return {
          ...item,
          completed: index <= this.computedCheckStep,
          sub_title:
            index == this.computedCheckStep
              ? this.getProjectsExtraProject?.subStage
              : null,
          clickFunction: this.handelClickUpdateStage
        }
      })
    },
    computedCheckStep() {
      let indexOf = this.computedStep
        .map(i => i.id)
        .indexOf(this.getProjectsExtraProject?.stage?.id)
      return indexOf
    },
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    }
  },
  methods: {
    ...mapActions([
      'refrechProject',
      'fetchEtapeSubstage',
      'updateProject',
      'resetErrorProject',
      'addEvent',
      'fetchALLTypeEventOfProject',
      'updateProjectOwner',
      'getAllUsers',
      'fetchHistoryActivtyEvent',
      'addActions',
      'addNewHistoryAction',
      'fetchDataForActions',
      'fetchAllActions',
      'addNewAction',
      'addEmailAction',
      'addMessageAction'
    ]),
    // Activer ou desactiver le drag and drop
    toggleDragAndDrop() {
      this.dragAndDropEnabled = !this.dragAndDropEnabled // Inverse l'état du glisser-déposer
    },
    getCurrencySymbol(currencyCode) {
      return currencySymbols[currencyCode] || currencyCode
    },
    refreshEditInputInformation() {
      this.loadingUpdateBlock = true
      setTimeout(() => {
        this.loadingUpdateBlock = false
      }, 2000)
    },
    toggleTabs() {
      // this.menuFile : variable content selected tabss
      if (this.menuFile == 'tab-0') {
        this.handelClickActivityTab()
      }
    },
    closeModal(type) {
      if (type == 'stage') {
        this.updateProjectState = false
        this.stateToUpdate = null
        return
      }
      if (type == 'task') {
        this.newTaskModal = false
        this.newTask = null
        return
      }
      if (type == 'action') {
        this.openModalAcion = false
        this.newAction = null
        this.fieldsAction = []
        return
      }
    },
    //assign Moethod
    async assignProject(item) {
      const res = await this.updateProjectOwner({
        entity: this.getProjectsExtraProject,
        field: 'project_owner',
        value: item.id
      })
      return res
    },
    //Stage Methods
    async handelClickUpdateStage(item) {
      this.resetErrorProject()
      this.stateToUpdate = item
      await this.fetchEtapeSubstage({
        cstage_id: item.id,
        stage: item,
        project_id: this.data.id
      })
      this.updateProjectState = true
    },
    async handelUpdateStage(event) {
      const res = await this.updateProject({
        entity: this.getProjectsExtraProject,
        field: 'cstage_id',
        value: this.stateToUpdate.id,
        substage: {
          field: 'csubstage_id',
          value: event && event.id ? event.id : null
        },
        step_update: {
          field: 'step_id',
          value:
            this.getProjectsExtraProject?.pipeline &&
            this.getProjectsExtraProject.pipeline.id
              ? this.getProjectsExtraProject.pipeline.id
              : null
        }
      })
      if (res) {
        await this.fetchEtapeSubstage({
          cstage_id: this.stateToUpdate.id,
          stage: this.stateToUpdate,
          project_id: this.data.id
        })
        return 'data_updated'
      }
      return false
    },
    //Action Method
    handelEventAction(item) {
      switch (item.key) {
        case 'task':
          this.handelEventTask(item)
          break
        default:
          this.handelAction(item)
          break
      }
    },
    async handelAction(item) {
      const response = await this.fetchDataForActions(
        this.getProjectsExtraProject.id
      )
      if (response) {
        if (item.key == 'message') {
          this.titleModalTask = 'message'
          this.newAction = {
            message: '',
            to: [
              {
                value:
                  response.data && response.data.phone
                    ? response.data.phone
                    : null,
                isValid: false
              }
            ],
            type: item.key,
            attachments: [],
            project_id: this.getProjectsExtraProject.id,
            scheduled_at: null
          }
          this.fieldsAction = ['phone', 'textArea']
          this.openModalAcion = true
          return
        }
        if (item.key == 'email') {
          this.titleModalTask = 'email'
          this.newAction = {
            message: null,
            to: [
              {
                value:
                  response.data && response.data.email
                    ? response.data.email
                    : null
              }
            ],
            subject: null,
            type: item.key,
            attachments: [],
            project_id: this.getProjectsExtraProject.id,
            scheduled_at: null
          }
          this.fieldsAction = ['email', 'subject', 'editor']
          this.openModalAcion = true
          return
        }
      }
    },
    convertDate(inputDate) {
      const [datePart, timePart, meridian] = inputDate.split(' ')
      const [year, month, day] = datePart.split('-')
      let [hours, minutes] = timePart.split(':')

      if (meridian.toLowerCase() === 'pm' && hours !== '12') {
        hours = String(parseInt(hours, 10) + 12)
      }

      if (meridian.toLowerCase() === 'am' && hours === '12') {
        hours = '00'
      }

      return `${day}-${month}-${year} ${hours.padStart(2, '0')}:${minutes}:00`
    },

    async handelValidateAction(item) {
      //api whatsup
      let data = { ...item }
      if (data.scheduled_at) {
        data.scheduled_at = this.convertDate(data.scheduled_at)
      }
      var resActions = null
      if (item.type == 'email') {
        resActions = await this.addEmailAction(data)
      } else {
        resActions = await this.addMessageAction(data)
      }
      // const  resActions = await this.addActions(data)
      if (this.menuFile == 'tab-0' && resActions.succes) {
        this.refreshHistoryActivity()
      }
      if (resActions.succes) {
        this.alertType = 'success'
        this.alertMessage =
          this.$t(this.titleModalTask) + ' ' + this.$t('send_succes')
        this.showAlert = true
      }
      setTimeout(() => {
        this.showAlert = false
      }, 4000)
      return resActions
    },
    //Task Methods
    handelEventTask() {
      this.titleModalTask = 'add_new_task'
      this.disabledType = false
      this.newTask = {
        name: null,
        detail: null,
        start: null,
        end: null,
        type: null,
        fields: [],
        status: 'En attente',
        assigned_user_id: null,
        projet_id: this.getProjectsExtraProject.id,
        color: 'blue'
      }
      this.newTaskModal = true
    },
    async handelNewTask(task) {
      if (task.id) {
        //to update task
      } else {
        let payload = task

        payload.start = payload.start
          ? moment(payload.start, 'YYYY-MM-DD HH:mm').format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : payload.end
        payload.end = payload.end
          ? moment(payload.end, 'YYYY-MM-DD HH:mm').format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : payload.end
        const response = await this.addEvent(payload)
        if (response) {
          // refresh historique activity
          this.refreshHistoryActivity()
          this.$alert('', this.$t('task_aded'), 'success')
          return { succes: true }
        }
        return { succes: false, error: this.getEventError }
      }
    },
    refreshHistoryActivity() {
      if (this.getCurentProjectType?.id) {
        this.fetchHistoryActivtyEvent({
          page: 1,
          per_page: 10,
          type_projet_id: this.getCurentProjectType.id,
          projet_id: this.data.id
        })
      }
    },
    handelEventUpdateTask(event) {
      this.titleModalTask = 'task_edit'
      this.disabledType = true
      this.newTask = event
      this.newTask.projet_id = this.getProjectsExtraProject.id
      this.newTaskModal = true
    },
    //Activity Methods
    handelClickActivityTab() {
      this.fetchALLTypeEventOfProject({ project_id: this.data.id })
      // this.fetchAllActions()
    }
  },
  watch: {
    getProjectsError(newValue) {
      this.errorMessages = newValue
    },
    getContactsError(newValue) {
      this.errorMessages = newValue
    }
  },
  mounted() {
    this.handelClickActivityTab()
  }
}
</script>

<style scoped lang="scss">
.file-project-immo {
  gap: 10px;
  display: flex;
  flex-direction: column;
  .block-file {
    width: 100%;
    padding: 6px 16px 6px 8px;
    border-radius: 8px;
    background: linear-gradient(0deg, #ece8f4, #ece8f4),
      linear-gradient(0deg, #ffffff, #ffffff);
    border: 1px solid #ece8f4;
    background: #ffffff;
    cursor: pointer;
  }
  .first-block {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    // gap: 19px;
    .back-class {
      padding: 6px 16px 6px 16px;
      border-radius: 3px;
      font-size: 12px;
      font-weight: 500;
      color: #5c2dd3;
      border: 1px dashed #5c2dd3;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      margin-right: 19px;
    }
  }
  .second-block {
    height: 164px;
    text-align-last: start;
    justify-content: flex-start;
    padding: 6px 0px 6px 0px;

    .info-block,
    .stade-block {
      border-bottom: 1px solid #ece8f4;
    }
    .title-block,
    .details-block {
      letter-spacing: 0em;
      text-align: center;
      color: #2f2f2f;
    }
    .title-block {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      height: 21px;
      text-wrap: nowrap;
    }
    .details-block {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    .info-block {
      padding: 0px 16px 6px 16px;
      display: flex;
      justify-content: space-between;

      .border-r {
        border-right: 1px solid #c9c8d3;
        padding-right: 10px;
      }
      .left-side {
        display: flex;
        gap: 10px;
      }
    }
    .stade-block {
      display: flex;
      gap: 20px;
      align-items: center;
      height: 73px;
      padding: 0px 16px 0px 16px;
    }
    .stepper-block {
      width: calc(100% - 195px);
    }
  }
  .third-block {
    height: calc(100vh - 343px);
    overflow-y: auto;
    padding-bottom: 5px;
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #bdbdbd;
      cursor: pointer !important;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: 0px 1px 0px 0px #0000001f inset;
      box-shadow: 0px 1px 0px 0px #0000001f inset;
      border-radius: 4px;
      background-color: #eeeeee;
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 5px;
    }
  }
}
</style>
<style lang="scss">
.menu-block {
  display: flex;
  align-items: center;
  .v-slide-group__content {
    margin-left: 0px;
  }
  .v-tabs-bar {
    height: 37px !important;
  }
  .v-slide-group__wrapper {
    border-radius: 8px;
  }
  .tab-menu-block {
    &.v-tab {
      text-decoration: none;
      text-transform: none;
      color: #2f2f2f !important;
      line-height: 13px !important;
      padding: 9px 16px 9px 16px !important;
      font-size: 12px !important;
      font-weight: 600 !important;

      letter-spacing: 0px;
      text-align: center;
      &.v-tab--active {
        color: #5c2dd3 !important;
      }
    }
  }
}
</style>
