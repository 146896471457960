import { render, staticRenderFns } from "./GridFile.vue?vue&type=template&id=212cb1a8&scoped=true"
import script from "./GridFile.vue?vue&type=script&lang=js"
export * from "./GridFile.vue?vue&type=script&lang=js"
import style0 from "./GridFile.vue?vue&type=style&index=0&id=212cb1a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "212cb1a8",
  null
  
)

export default component.exports