<template>
  <div class="block-proposals">
    <div class="header-block">
      <div class="left-header">
        <div class="list-class-proposals">
          <div class="item-list">
            <v-menu offset-y v-model="openEdit">
              <!------------------- Edit View  ------------------->
              <template v-slot:activator="{ attrs, on }">
                <div
                  color="transparent"
                  class="black--text btn-edit-view"
                  v-bind="attrs"
                  v-on="on"
                  @click="openEditView"
                >
                  <span class="icon-edit-view">
                    <IconColumns />
                  </span>
                  <!-- Conserver l'icône existante -->
                  <span class="ml-1 label-btn-edit-view ">
                    {{ $i18n.locale == 'fr' ? 'Modifier la vue' : 'Edit View' }}
                  </span>
                </div>
              </template>

              <!-- <v-list>
                <v-list-item v-for="item in items" :key="item" link>
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list> -->
            </v-menu>
          </div>
          <!------------------------- Button suppression et nbr des documents selectionner  --------------------->
          <template v-if="showActionHeaderEditable">
            <div
              class="item-list color-red"
              @click.prevent.stop="handelDeleteMultiResource"
            >
              <font-awesome-icon :icon="['far', 'trash']" class="img-h-16" />
              <span class="mt-1">
                {{
                  $i18n.locale == 'fr'
                    ? 'Supprimer document/documents'
                    : 'Delete document/documents'
                }}</span
              >
            </div>
            <div class="delete-projet ml-2 mt-1">
              {{
                $i18n.locale == 'fr'
                  ? `${selectedCheck.length} documents Sélectionné`
                  : `${selectedCheck.length} Selected documents`
              }}
            </div>
          </template>

          <!---------------- Button ajout Document  ----------------->
          <template v-else>
            <div class="block-action-add-propoasls">
              <v-btn
                dark
                class="ml-3"
                color="#45148F"
                @click.prevent.stop="handleClickBtnAddProposal"
              >
                <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon>
                <span class="label-btn">
                  {{
                    $i18n.locale == 'fr' ? 'Ajouter document' : 'Add document'
                  }}</span
                >
              </v-btn>
            </div>
          </template>
        </div>
      </div>

      <div class="right-header">
        <div class="block-action-add-propoasls" v-if="showActionHeaderEditable">
          <v-btn
            dark
            class="mr-3"
            color="#45148F"
            @click.prevent.stop="telechargerDocument"
          >
            <!-- <v-icon class="sz-icon-plus mr-1">mdi-plus</v-icon> -->
            <span class="label-btn">
              {{ $i18n.locale == 'fr' ? 'Visualiser' : 'View' }}</span
            >
          </v-btn>
        </div>
        <div class="flex-center ml-2">
          <template v-if="!showActionHeaderEditable">
            <div
              class="btn-list mr-2"
              :class="{ active: selectedDefaultBtn == 'grid' }"
              @click.prevent.stop="showGrid"
            >
              <font-awesome-icon
                :icon="['fal', 'grid-2']"
                class="img-h-19 color-img"
              />
            </div>

            <div
              class="btn-list"
              :class="{ active: selectedDefaultBtn == 'list' }"
              @click.prevent.stop="showList"
            >
              <font-awesome-icon
                :icon="['fas', 'bars']"
                class="img-h-19 color-img"
              />
            </div>
          </template>
          <!----------------- Filter  ------------------------------>
          <!-- <template> -->
          <!-- <div class="choisi-filter-propoasls">
              <v-menu
                offset-y
                origin="center center"
                transition="scale-transition"
                :nudge-bottom="8"
                :nudge-left="6"
                :nudge-width="15"
              > -->
          <!-- <template v-slot:activator="{ attrs, on }">
                  <div v-bind="attrs" v-on="on">
                    <span class="label-btn">
                      {{
                        $i18n.locale === 'fr'
                          ? `Décision du client : `
                          : `Client Decision : `
                      }}</span
                    ><span class="font-sz-13">{{
                      $i18n.locale === 'fr'
                        ? `${selectedFilterClientDecision}`
                        : `${selectedFilterClientDecision}`
                    }}</span>
                    <v-progress-circular
                      v-if="false"
                      indeterminate
                      color="#5C2DD3"
                      class="sz-loader-icon"
                    ></v-progress-circular>
                    <v-icon class="icon-flech ml-3" v-else
                      >mdi-chevron-down</v-icon
                    >
                  </div>
                </template> -->
          <!-------------------------- Filter -------------------------->
          <!-- <v-list class="list-type-projet-scroll">
                  <div v-if="filteClientDecision && filteClientDecision.length">
                    <v-list-item
                      class="list-type-projet-item"
                      v-for="item in filteClientDecision"
                      :key="item.label"
                    >
                      <v-list-item-title
                        class=" list-type-onglet-projet block-flex-align-space-between"
                      >
                        <div
                          class="font-text font-sz-12 bold-700 title-type-popover"
                        >
                          {{ item.label }}
                        </div>
                      </v-list-item-title>
                    </v-list-item>
                  </div>
                  <v-list-item class="list-type-projet-item" v-else>
                    <v-list-item-title
                      class=" list-type-onglet-projet block-flex-align-space-between"
                    >
                      <span
                        class="text-center mr-2 font-text font-sz-12 bold-700"
                      >
                        {{
                          $i18n.locale === 'fr' ? 'Aucun choix' : 'no choice'
                        }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list> -->
          <!-- </v-menu>
            </div> -->

          <!---------------------------- Filter  --------------------------->
          <!-- <div class="choisi-filter-propoasls ml-2">
              <v-menu
                offset-y
                origin="center center"
                transition="scale-transition"
                :nudge-bottom="8"
                :nudge-left="6"
                :nudge-width="15"
              >
                <template v-slot:activator="{ attrs, on }">
                  <div v-bind="attrs" v-on="on">
                    <span class="label-btn">
                      {{
                        $i18n.locale === 'fr' ? `Développeur: ` : `Developer: `
                      }}</span
                    ><span class="font-sz-13">{{
                      $i18n.locale === 'fr'
                        ? `${selectedFilterDeveloper}`
                        : `${selectedFilterDeveloper}`
                    }}</span>
                    <v-progress-circular
                      v-if="false"
                      indeterminate
                      color="#5C2DD3"
                      class="sz-loader-icon"
                    ></v-progress-circular>
                    <v-icon class="icon-flech ml-3" v-else
                      >mdi-chevron-down</v-icon
                    >
                  </div>
                </template>
                <div v-if="filterDeveloper && filterDeveloper.length">
                  <v-list-item
                    class="list-type-projet-item"
                    v-for="item in filterDeveloper"
                    :key="item.label"
                  >
                    <v-list-item-title
                      class=" list-type-onglet-projet block-flex-align-space-between"
                    >
                      <div
                        class="font-text font-sz-12 bold-700 title-type-popover"
                      >
                        {{ item.label }}
                      </div>
                    </v-list-item-title>
                  </v-list-item>
                </div>
                <v-list class="list-type-projet-scroll" v-else>
                  <v-list-item class="list-type-projet-item">
                    <v-list-item-title
                      class=" list-type-onglet-projet block-flex-align-space-between"
                    >
                      <span
                        class="text-center mr-2 font-text font-sz-12 bold-700"
                      >
                        {{
                          $i18n.locale === 'fr' ? 'Aucun choix' : 'no choice'
                        }}
                      </span>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div> -->
          <!-- </template> -->
        </div>
      </div>
    </div>
    <v-divider class="mt-2"></v-divider>
    <div class="content-block pt-2">
      <dataImmoTable
        v-if="selectedDefaultBtn == 'list'"
        :perPage="per_page"
        @download="downloadFile"
        :key="`dataImmoTable_${tableKey}`"
        :Loader="getResourceLoading"
        :Total="fileCount"
        :computedHeaderColumnTable="computedHeaderColumnTable"
        :computedTableBoard="computedTableBoard"
        :computedLastPage="computedLastPage"
        @pagination="pagination"
        @SlectedItems="selectedMultiPropoasls"
        @openModalFileProjet="handleUpdateClick"
        :computedRestPage="computedRestPage"
        styleComponents="document"
        :resetCheckbox="resetCheckbox"
      >
      </dataImmoTable>

      <GridFile
        v-else
        @download="downloadFile"
        :styleComponents="'document'"
        :computedTableBoard="computedTableBoardGrid"
        :total="fileCount"
        :computedLastPage="computedLastPage"
        @pagination="pagination"
        :isFile="true"
        @deleted="PrepareDeleteGrid"
      ></GridFile>
    </div>

    <!-- **************************************** START *************************************************** -->
    <!-- OPEN MODAL ADD DOCUMENT -->
    <div class="overlay-modal" v-if="modalAddProposals">
      <portal to="new-project-modal">
        <ModalRightDynamic @closeModal="handleClose('modalAddProposals')">
          <template #title>
            {{ $i18n.locale === 'fr' ? 'Ajouter document' : 'Add document' }}
          </template>
          <template #content-form>
            <v-form ref="modalAddProposals">
              <div>
                <!-- IMPORTE IMAGE / FILE -->
                <div class="container" @drop="handleFileDropAdd">
                  <div class="file-wrapper">
                    <input
                      type="file"
                      name="file-input"
                      id="uploadInput"
                      multiple
                      @change="handleFileInputAdd"
                    />
                    <div class="upload_label">
                      <font-awesome-icon
                        :icon="['far', 'file-arrow-up']"
                        class="icon-upload"
                      />
                      <p class="color-black mb-2">
                        <span class="drag-title"
                          ><u>
                            {{
                              $i18n.locale === 'fr'
                                ? 'Click pour uploader'
                                : 'Click to upload'
                            }}</u
                          ></span
                        >
                        {{
                          $i18n.locale === 'fr'
                            ? 'Ou glissez et déposer'
                            : 'or drag and drop'
                        }}
                      </p>
                      <p class=" mt-0 mb-0">SVG, PNG, JPG or GIF (max. 3MB)</p>
                    </div>
                  </div>
                </div>
                <div v-if="files.length > 0" class="uploaded-files">
                  <div
                    v-for="(file, index) in files"
                    :key="index"
                    class="file-item"
                  >
                    <v-icon
                      @click="removeFile(index)"
                      style="cursor: pointer; color: red; position: absolute; right:4%;"
                      >mdi-close</v-icon
                    >
                    <p style="color:#704ad1">{{ file.name }}</p>
                  </div>
                </div>
              </div>
            </v-form></template
          >

          <template #content-message>
            <div class="message">
              <div v-if="getResourceLoading" class="loading">
                {{ $t('loading') }}
              </div>
              <div v-if="hasError" class="error-msg">
                <ul v-if="Array.isArray(errorMessages)">
                  <li v-for="(e, index) in errorMessages" :key="index">
                    {{ $t(e) }}
                  </li>
                </ul>
                <span v-else>{{ $t(errorMessages) }}</span>
              </div>
            </div>
          </template>
          <template #content-actions>
            <v-btn
              color="#45148F"
              dark
              @click.prevent.stop="handleValiderAddFile"
            >
              <span class="label-btn-form-immo font-sz-11">
                {{
                  $i18n.locale === 'fr' ? 'Ajouter document' : 'Add document'
                }}</span
              >
            </v-btn>
            <v-btn
              text
              class="ml-3"
              @click.prevent.stop="handleClose('modalAddProposals')"
              variant="outline-secondary"
            >
              <span class="label-btn-form-immo font-sz-13 color-black">
                {{ $t('btnCancel') }}</span
              >
            </v-btn></template
          >
        </ModalRightDynamic>
      </portal>
    </div>
    <!-- CLOSE MODAL ADD PROPOASLS  -->
    <!-- **************************************** END *************************************************** -->

    <!-- OPEN MODAL DELETED PROPOSALS MULTI -->
    <v-dialog v-model="modalDeletedProposals" max-width="500px">
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Supprimer un document'
                : 'Delete Document'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="handleClose('modalDeletedProposals')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="body-card center-text  mt-2">
          <div class="mb-3 color-black-light">
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? 'les documents' : 'the document'
              })
            }}
            ?

            <ul v-if="selectedCheck && selectedCheck.length" class="mt-2">
              <li
                v-for="item in selectedCheck"
                :key="item.id"
                class="text-capitalize mb-2"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="loading" class="loading">
              {{ $t('loading') }}
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleDeletedProposals"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="handleClose('modalDeletedProposals')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- CLOSE MODAL DELETED PROPOSALS MULTI -->
  </div>
</template>
<script>
import GridFile from '@/views/CRMILA26/Projects/immobilier/pipline/Cover/GridFile.vue'

import ModalRightDynamic from '@/views/CRMILA26/Projects/Dialogs/ModalRightDynamic.vue'
import dataImmoTable from '@/views/CRMILA26/Projects/immobilier/pipline/immoTable/dataImmoTable.vue'
import { mapActions, mapGetters } from 'vuex'
import IconColumns from '@/views/CRMILA26/Projects/immobilier/pipline/IconSvg/Columns.vue'

export default {
  components: {
    GridFile,
    ModalRightDynamic,
    dataImmoTable,
    IconColumns
  },
  props: { projet: { required: true } },
  data() {
    return {
      modalAddProposals: false,
      selectedDefaultBtn: 'list',
      typePropoasl: 1,
      showActionHeaderEditable: false,
      modalEditProposals: false,
      modalDeletedProposals: false,
      selectedFilterDeveloper: 'ALL',
      selectedFilterClientDecision: 'Interested',
      affecterResourceToProjet: null,
      files: [],
      proposalsToUpdate: null,
      proposalsToUpdateOriginal: null,
      errorMessages: null,
      loading: false,
      page: 1,
      per_page: 10,
      filterDeveloper: [{ label: 'All' }],
      filteClientDecision: [{ label: 'Interested' }],
      restPage: false,
      tableKey: 0,
      selectedCheck: null,
      items: [...Array(4)].map((_, i) => `Item ${i}`),
      showActionHeaderEditableModal: false,
      selectedCheckModal: null,
      resetCheckbox: false,
      openEdit: false
    }
  },
  methods: {
    ...mapActions([
      'addFichier',
      'fetchFiles',
      'affecterResourceToProject',
      'fetchAllTypeResource',
      'fetchOneTypeResource',
      'addResource',
      'resetErrorResource',
      'resetErrorTypeResource',
      'affecterNonResourceToProject',
      'removeResourceAffecteToProjet',
      'deleteFile'
    ]),

    // Méthode pour retirer un fichier de la liste
    removeFile(index) {
      this.files.splice(index, 1)
    },

    refreshDataImmoTable() {
      // Incrémenter la clé pour forcer la mise à jour du composant
      this.tableKey++
    },
    handelopenModalFileProjet() {},
    // UPDATE PROPOSALS
    handleUpdateClick(item) {
      this.proposalsToUpdate = { ...item }
      this.proposalsToUpdateOriginal = item
      this.modalEditProposals = true
    },

    downloadFile(item) {
      const fileUrl = item.url // Replace this with your file's link
      const link = document.createElement('a')
      link.href = fileUrl
      link.setAttribute('download', '') // This attribute prompts the browser to download the file
      link.setAttribute('target', '_blank') // This attribute prompts the browser to download the file

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    async telechargerDocument() {
      // try {
      this.selectedCheck.forEach(document => {
        window.open(document.url, '_blank')
      })

      this.selectedCheck = []
      // }
      //  catch (error) {
      // }
    },
    updateProposalsFunction(data) {
      this.proposalsToUpdate.fieldsUpdate = data
    },

    handleFileDropUpdate(e) {
      document.getElementById('uploadInputUpdate').value = ''
      let droppedFiles = e.dataTransfer.files
      if (!droppedFiles) return
      ;[...droppedFiles].forEach(f => {
        this.proposalsToUpdate.files = f
      })
    },
    handleFileInputUpdate(e) {
      let files = e.target.files
      if (!files) return
      // Vérifier si proposalsToAdd.files est déjà initialisé comme un tableau
      if (!Array.isArray(this.proposalsToUpdate.files)) {
        this.proposalsToUpdate.files = []
      }
      // Ajouter chaque fichier sélectionné au tableau proposalsToUpdate.files
      ;[...files].forEach(f => {
        this.proposalsToUpdate.files.push(f)
      })
      // // Réinitialiser la valeur de l'élément d'entrée de fichier pour permettre une nouvelle sélection
      // document.getElementById('uploadInputUpdate').value = ''
    },
    // ADD PROPOSALS
    async changeTypeProposals(val) {
      if (val == 1) {
        this.$refs.modalAddProposals.resetValidation()
      } else {
        await this.affecterNonResourceToProject({ projet_id: this.projet.id })
      }
      this.resetErrorResource()
      this.resetErrorTypeResource()
    },
    updateFunction(field) {
      this.proposalsToAdd.fields = field
    },
    async changeFieldsTypeResource(field) {
      if (field) {
        await this.fetchOneTypeResource(field.id)
      }
    },
    handleClickBtnAddProposal() {
      this.modalAddProposals = true
    },
    resetFiles() {
      this.files = []
      this.files = []
    },
    handleClose(ref) {
      this[ref] = false
      if (ref == 'modalAddProposals') {
        this.$refs.modalAddProposals.resetValidation()
      }
      this.resetFiles()
      this.resetErrorResource()
      this.resetErrorTypeResource()
    },

    uploadFile(event) {
      this.files = event.target.files
    },
    // Add File
    async handleValiderAddFile() {
      if (this.$refs.modalAddProposals.validate()) {
        this.loading = true
        const formData = new FormData()
        for (const i of Object.keys(this.files)) {
          formData.append('fichier' + '[' + i + ']' + '[file]', this.files[i])
          formData.append('fichier' + '[' + i + ']' + '[categorie]', 'Diver')
        }
        formData.append('projet_id', this.projet.id)
        const response = this.addFichier({ data: formData })

        if (response) {
          this.handleClose('modalAddProposals')
          this.affecterResourceToProject({
            page: this.page,
            per_page: this.per_page,
            projet_id: this.projet.id
          })
        }
        this.resetFiles()
        this.resetFiles()
        this.loading = false
      }
    },

    handleFileDropAdd(e) {
      document.getElementById('uploadInput').value = ''
      let droppedFiles = e.dataTransfer.files
      if (!droppedFiles) return

      this.files = [...droppedFiles]
    },

    handleFileInputAdd(e) {
      let files = e.target.files
      if (!files) return

      // Mettez à jour le tableau files avec les nouveaux fichiers sélectionnés
      this.files = [...files]

      // Réinitialiser la valeur de l'élément d'entrée de fichier pour permettre une nouvelle sélection
      document.getElementById('uploadInput').value = ''
    },

    // AFFICHAGE TABLE
    openEditView() {
      this.openEdit = true
    },
    pagination(page) {
      this.page = page
      this.handelFilter()
    },
    paginationModal(page) {
      this.page = page
      this.affecterNonResourceToProject({
        page: this.page,
        per_page: this.per_page,
        projet_id: this.projet.id
      })
    },
    handelFilter() {
      this.affecterResourceToProject({
        page: this.page,
        per_page: this.per_page,
        projet_id: this.projet.id
      })
    },
    selectedMultiPropoasls(event) {
      this.showActionHeaderEditable = event.length > 0
      this.selectedCheck = event
    },
    selectedMultiPropoaslsModal(event) {
      this.showActionHeaderEditableModal = event.length > 0
      this.selectedCheckModal = event
    },
    deselectAllInDataImmo() {
      this.resetCheckbox = !this.resetCheckbox
    },
    showModalEdit() {
      this.showActionHeaderEditable = false
      this.modalEditProposals = true
    },
    showList() {
      this.selectedDefaultBtn = 'list'
    },
    showGrid() {
      this.selectedDefaultBtn = 'grid'
    },
    handelDeleteMultiResource() {
      this.modalDeletedProposals = true
    },

    async PrepareDeleteGrid(file) {
      this.selectedCheck = [file]
      this.handleDeletedProposals()
    },
    async handleDeletedProposals() {
      this.loading = true

      try {
        let filesToDelete = this.selectedCheck
        for (let i = 0; i < filesToDelete.length; i++) {
          // Call the deleteFile action to delete the selected files
          this.deleteFile({
            project: this.projet,
            file: filesToDelete[i]
          })
        }
        // Close the modal and refresh the data
        this.handleClose('modalDeletedProposals')
        this.refreshDataImmoTable()
        this.showActionHeaderEditable = false
      } catch (error) {
        // Handle error as needed
      } finally {
        this.loading = false
      }
    },

    normalizedContains(haystack, needle) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi')
      return regExp.test(this.removeDiacritics(haystack))
    },
    removeDiacritics(str) {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    },
    extractFileName: function(fullFileName) {
      const firstDotIndex = fullFileName.indexOf('.')
      if (firstDotIndex !== -1) {
        const fileNameWithExtension = fullFileName.substring(firstDotIndex + 1)
        return fileNameWithExtension !== ''
          ? fileNameWithExtension
          : fullFileName
      } else {
        return fullFileName
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsFiles',
      'getProjectsFilesProcessing',
      'getTypeResourceLoading',
      'getTypeResource',
      'getResourceLoading',
      'countPaginationdFile',
      'getResource',
      'getResourceAffecteToProject',
      'getResourceNonAffecteToProject',
      'countPaginationAffecteResourceToProject',
      'fileCount'
    ]),
    computedRestPage() {
      return this.restPage
    },
    computedLastPage() {
      return Math.ceil(this.fileCount / this.per_page)
    },
    computedLastPageModal() {
      return Math.ceil(
        this.countPaginationAffecteResourceToProject / this.per_page
      )
    },
    computedHeaderColumnTable: function() {
      let header = [
        { key: 'title', name: 'File' },
        { key: 'created_by', name: 'Telecharger par' },
        { key: 'created_at', name: 'date de telechargement' },
        { key: 'download', name: 'telechargement' }
      ]
      const updatedHeaders = [
        ...header.map(i => {
          return {
            text: i.name,
            value: i.key,
            ...i
          }
        })
      ]
      return updatedHeaders
    },

    computedTableBoard: function() {
      if (this.getProjectsFiles && this.getProjectsFiles.length) {
        const newArray = this.getProjectsFiles.map(obj => {
          const fileName = this.extractFileName(obj.title)
          return {
            ...obj,
            title: fileName,
            created_by: obj?.created_by?.full_name
              ? obj?.created_by?.full_name
              : '-'
          }
        })
        return newArray
      }

      return []
    },

    computedTableBoardGrid: function() {
      if (this.getProjectsFiles && this.getProjectsFiles.length) {
        const newArray = this.getProjectsFiles.map(obj => {
          const fileName = this.extractFileName(obj.title)
          return {
            file_one: {
              ...obj,
              title: fileName,
              created_by: obj?.created_by?.full_name
                ? obj?.created_by?.full_name
                : '-'
            }
          }
        })
        return newArray
      }

      return []
    },

    computedTableBoardModal: function() {
      if (
        this.getResourceNonAffecteToProject &&
        this.getResourceNonAffecteToProject.length
      ) {
        const search = this.affecterResourceToProjet
          ? this.affecterResourceToProjet.trim()
          : this.affecterResourceToProjet

        const newArray = this.getResourceNonAffecteToProject.map(obj => ({
          type_show:
            obj.type && obj.type.designation ? obj.type.designation : obj.type,
          ...obj
        }))
        if (!search) {
          return newArray
        }
        return newArray.filter(c =>
          this.normalizedContains(
            c.designation.toLowerCase(),
            search.toLowerCase()
          )
        )
      }

      return []
    },
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    }
  },
  watch: {
    selectedDefaultBtn(newValue, oldValue) {
      // Lorsque le bouton sélectionné change, rafraîchir le composant dataImmoTable
      if (newValue === 'list' && newValue !== oldValue) {
        this.refreshDataImmoTable()
      }
    },
    getResourceError(newValue) {
      this.errorMessages = newValue
    },
    getTypeResourceError(newValue) {
      this.errorMessages = newValue
    }
  },
  async mounted() {
    this.refreshDataImmoTable()
    //   if (this.projet) {
    //     await this.affecterResourceToProject({
    //       page: this.page,
    //       per_page: this.per_page,
    //       projet_id: this.projet.id
    //     })
    //     this.fetchAllTypeResource()
    //   }
    if (this.projet) {
      await this.fetchFiles({
        page: this.page,
        per_page: this.per_page,
        id: this.projet.id
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.block-proposals {
  width: 100%;
  padding: 6px 16px 6px 8px;
  border-radius: 8px;
  background: linear-gradient(0deg, #ece8f4, #ece8f4),
    linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #ece8f4;
  background: #ffffff;
  cursor: pointer;
  .header-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-header {
      white-space: nowrap;
      .delete-projet {
        color: #45148f;

        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .uploaded-files {
      margin-top: 20px;
    }

    .file-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .file-item p {
      margin: 0;
    }

    /* Style pour le bouton de suppression */
    .file-item v-icon {
      cursor: pointer;
      color: red;
    }

    .right-header {
      display: flex;
      align-items: center;

      .btn-list {
        padding: 8px;
        gap: 10px;
        border-radius: 4px;
        width: 39px;
        height: 39px;
        text-align: center;
        &:hover,
        &.active {
          background-color: #ececec;
        }
      }
      .color-img {
        color: #7f8c8d !important;
      }
      .choisi-filter-propoasls {
        border-radius: 4px;
        background-color: rgba(241, 245, 249, 1);
        padding: 5px 7px;
        white-space: nowrap;
        cursor: pointer;
        .label-btn {
          font-size: 12px;

          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0.15px;
        }
      }
    }

    .block-action-add-propoasls {
      display: flex;
      line-height: 24px;
      gap: 5px;
      letter-spacing: 0px;
      border-right: 1px solid #c9c8d3;
      align-items: center;
      justify-content: flex-start;
      .label-btn {
        font-size: 13px;

        text-align: center;
        text-transform: capitalize !important;
      }
    }
  }
}
</style>
<style lang="scss">
.header-block {
  .block-action-add-propoasls {
    .v-btn:not(.v-btn--round).v-size--default {
      height: 33px !important;
    }
  }
}
.delete-projet-proposals {
  color: #45148f;

  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  &:hover {
    text-decoration: underline;
  }
}
.list-class-proposals {
  display: flex;
  .item-list {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    line-height: 24px;
    gap: 5px;
    letter-spacing: 0px;
    color: #2f2f2f;
    padding: 0px 9px 0px 6px;
    border-right: 1px solid #c9c8d3;
    align-items: center;
    justify-content: flex-start;
    &:hover {
      background: #f1f5f9;
    }
  }
}
.list-class-proposals-modal {
  display: flex;
  .item-list,
  .item-list-hover {
    font-size: 13px;
    font-weight: 500;
    display: flex;
    line-height: 24px;
    gap: 5px;
    letter-spacing: 0px;
    color: #2f2f2f;
    padding: 0px 9px 0px 6px;
    align-items: center;
    cursor: pointer;
    justify-content: flex-start;
    &.border-right {
      border-right: 1px solid #c9c8d3;
    }
  }
  .item-list-hover {
    &:hover {
      background: #f1f5f9;
    }
  }
}
.label-radio-propoasls {
  .v-label {
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center !important;
    color: #212121 !important;
  }
}
.label-global {
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 17.07px !important;
  color: rgba(0, 0, 0, 0.6) !important;
}
.label-btn-form-immo {
  font-weight: 500 !important;
  text-align: center !important;
  text-transform: capitalize !important;
}
.label-btn-edit-view {
  font-size: 13px !important;
  position: relative;
  top: 2px;

  text-transform: capitalize !important;
}
.icon-edit-view {
  top: 5px;
  position: relative;
}
.line-hr-modal-proposals {
  margin-top: -10px;
}
.width-switch-proposals {
  width: 30%;
}
.btn-edit-view {
  .v-btn:not(.v-btn--round).v-size--small {
    height: 0px !important;

    &:hover {
      background-color: transparent !important;
    }
  }
  &:hover,
  &:focus,
  &:active {
    background-color: transparent !important;
  }
}
</style>
<style lang="scss" scoped>
// ----------------------------------- style image drag and drop -----------------------------------
.container {
  height: 230px;
  width: 100%;
  .file-wrapper {
    text-align: center;
    width: 105%;
    height: 90%;
    margin: 0px 0px 0px -12px;
    vertical-align: middle;
    position: relative;
    overflow: hidden;
    border: 1px dashed rgba(92, 45, 211, 1);
    background-color: transparent;
    border-radius: 5px;
    box-shadow: 1px 2px 5px 5px #f5f5f5;
    input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
      z-index: 5;
    }
    .display {
      display: none;
    }
    .upload_label {
      font-size: 40px;
      position: relative;
      top: 32px;
      .drag-title {
        text-decoration: underline;
        font-size: 16px;

        font-weight: 400;
        color: #704ad1;
      }
      .color-black {
        color: #000;
      }
      p {
        font-size: 16px;
      }
      .icon-upload {
        width: 33px;
        height: 33px;
        margin-bottom: 15px;
        color: #704ad1;
      }
      .btn-upload-file {
        font-size: 12px;
        font-weight: normal;
        width: 60%;
        border-radius: 29px;
        background-color: #704ad1;
        padding: 11px 20px;
        border: 0;
        color: #fff;
        margin: auto;
      }
    }
  }
}
.overlay-modal {
  position: fixed;
  height: calc(100% - 0px);
  width: 100%;
  top: 0px;
  border: 0;
  background: #d6d6d64a;
  right: 0;
  left: 0;
  z-index: 101;
}
</style>
