<template>
  <div>
    <ModalRightDynamic @closeModal="handleClose()">
      <template #title>
        {{ hist.type_event.designation }}
      </template>
      <template #content-form>
        <v-form ref="ModalTask" class="mt-2">
          <!-- NOM EVENT -->
          <v-row class="row" style="font-weight: 600;">
            <v-col>
              <v-text-field
                :label="$t('name')"
                dense
                :persistent-placeholder="true"
                outlined
                color="#5C2DD3"
                item-color="#5C2DD3"
                v-model="hist.created_by.full_name"
                :rules="[v => !!v || $t('name') + ' ' + $t('msgOblg')]"
                :disabled="isDisabled"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!-- DESCRIPTION -->
          <v-row class="row" style="font-weight: 500;">
            <v-col>
              <v-textarea
                dense
                :label="$t('description')"
                v-model="hist.description"
                :persistent-placeholder="true"
                rows="3"
                outlined
                color="#5C2DD3"
                :disabled="isDisabled"
                style="font-size: 14px;"
              ></v-textarea>
            </v-col>
          </v-row>

          <!----- Premier composant pour la date de début --------->
          <div
            style="display: flex; justify-content: space-between;font-weight: 600;"
          >
            <!-- Vue CTK Date Time Picker pour la date de début -->
            <vue-ctk-date-time-picker
              style="width: calc(50% - 2%);"
              v-model="hist.date"
              color="#45148F"
              :inline="false"
              :buttonNowTranslation="'Today'"
              inputSize="sm"
              buttonColor="#45148F"
              locale="fr"
              label="Start Date"
              :no-value-to-custom-elem="true"
              :disabled="isDisabled"
              @input="checkRulesDate(['dateStart', 'dateEnd'])"
            >
              <v-text-field
                v-model="hist.date"
                :label="$t('date_debut')"
                @click:clear="hist.date = null"
                outlined
                readonly
                clearable
                dense
                ref="dateStart"
                :persistent-placeholder="true"
                :placeholder="$t('msgSelectDate')"
                prepend-inner-icon="mdi-calendar"
                color="#5C2DD3"
                required
                :disabled="isDisabled"
                validate-on-blur
                :rules="[v => !!v || $t('date_debut') + ' ' + $t('msgOblg')]"
              ></v-text-field>
            </vue-ctk-date-time-picker>

            <!-- Deuxième composant pour la date de fin -->
            <!-- Vue CTK Date Time Picker pour la date de fin -->
            <vue-ctk-date-time-picker
              style="width: calc(50% - 2%); margin-left: 4%;"
              v-model="hist.date"
              :min="'hist.date'"
              color="#45148F"
              :inline="false"
              :buttonNowTranslation="'Today'"
              inputSize="sm"
              buttonColor="#45148F"
              locale="fr"
              label="End Date"
              :no-value-to-custom-elem="true"
              :disabled="isDisabled"
              @input="checkRulesDate('dateEnd')"
              class="custom-ctk-component right-position "
            >
              <v-text-field
                v-model="hist.date"
                :label="$t('date_fin')"
                @click:clear="hist.date = null"
                outlined
                readonly
                clearable
                ref="dateEnd"
                dense
                :persistent-placeholder="true"
                :placeholder="$t('msgSelectDate')"
                prepend-inner-icon="mdi-calendar"
                color="#5C2DD3"
                required
                validate-on-blur
                :disabled="isDisabled"
                :rules="[v => !!v || $t('date_fin') + ' ' + $t('msgOblg')]"
              ></v-text-field>
            </vue-ctk-date-time-picker>
          </div>

          <!-- STATUS EVENT -->
          <v-row style="font-weight: 600;">
            <v-col>
              <v-text-field
                :clearable="true"
                color="#5C2DD3"
                :placeholder="$t('event_status')"
                item-color="#5C2DD3"
                v-model="hist.event_status"
                :items="getEventStatus"
                :persistent-placeholder="true"
                :label="$t('event_status')"
                item-text="text"
                dense
                item-value="value"
                outlined
                :disabled="isDisabled"
                :menu-props="{
                  bottom: true,
                  offsetY: true
                }"
                validate-on-blur
                :rules="[v => !!v || $t('event_status') + ' ' + $t('msgOblg')]"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <!-- ASSIGN USER TO EVENT -->
          <v-row style="font-weight: 600;">
            <v-col>
              <v-text-field
                :clearable="true"
                color="#5C2DD3"
                item-color="#5C2DD3"
                v-model="hist.assigned_to"
                :items="allUsers"
                :persistent-placeholder="true"
                :label="$t('assigned_to')"
                item-text="full_name"
                dense
                item-value="id"
                outlined
                :disabled="isDisabled"
                :menu-props="{
                  bottom: true,
                  offsetY: true
                }"
                validate-on-blur
                :rules="[v => !!v || $t('assigned_to') + ' ' + $t('msgOblg')]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <!-- TYPE EVENT -->
          <v-row style="font-weight: 600;">
            <v-col>
              <v-text-field
                :clearable="true"
                color="#5C2DD3"
                :loading="getTypeEventLoading"
                :placeholder="
                  $t('searchMsg', {
                    msg: $t('event_type')
                  })
                "
                item-color="#5C2DD3"
                v-model="hist.type"
                :items="getTypeEvent"
                :persistent-placeholder="true"
                :label="$t('event_type')"
                item-text="designation"
                dense
                return-object
                item-value="id"
                :disabled="isDisabled"
                :no-data-text="
                  $t('noDataOptionMsg', {
                    option: $t('event_type')
                  })
                "
                outlined
                :menu-props="{
                  bottom: true,
                  offsetY: true
                }"
                validate-on-blur
                :rules="[v => !!v || $t('event_type') + ' ' + $t('msgOblg')]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </template>
      <template #content-actions>
        <v-btn
          text
          class="ml-3"
          @click.prevent.stop="handleClose"
          variant="outline-secondary"
        >
          <span class="label-btn-form-immo color-black text-uppercase">
            {{ $t('btnCancel') }}</span
          >
        </v-btn></template
      >
    </ModalRightDynamic>
  </div>
</template>

<script>
import ModalRightDynamic from '../../../../Dialogs/ModalRightDynamic.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'task-modal',
  props: {
    hist: {
      required: true
    },
    disabledType: { default: true }
  },
  data() {
    return {
      ModalTask: false,
      isDisabled: true // Changez la valeur selon vos besoins
    }
  },
  computed: {
    ...mapGetters([
      'getTypeEventLoading',
      'getTypeEvent',
      'getEventStatus',
      'allUsers'
    ])
  },
  methods: {
    checkRulesDate(ref) {
      if (Array.isArray(ref)) {
        ref.forEach((item, index) => {
          setTimeout(() => {
            this.$refs[item].focus()
          }, index * 1000)
        })
      } else {
        this.$refs[ref].focus()
      }
    },
    handleClose() {
      this.$emit('close')
    }
  },
  components: {
    ModalRightDynamic
  },
  watch: {
    // Écouter les changements dans la date de début
    'hist.date': function(newVal) {
      this.hist.end = newVal
    }
  }
}
</script>
<style scoped>
.line-color-item {
  width: 3px;
  height: 17px;
  border-radius: 9px;
}

.title-list-item {
  gap: 4px;
  display: flex;
  align-items: center;
}
</style>
