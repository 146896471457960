<template>
  <div>
    <v-tabs
      class="menu-block-tabs"
      :hide-slider="hideSlider"
      :slider-color="sliderColor"
      :color="tabColor"
      :item-color="tabColor"
      v-model="internalMenuFile"
    >
      <v-tab
        v-for="(item, index) in menuItems"
        :key="index"
        :href="`#tab-${index}`"
        :color="tabColor"
        :item-color="tabColor"
        class="tab-menu-block"
        @click="toggleTabs"
      >
        {{ $t(item.key) + 's' }}
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
export default {
  props: {
    hideSlider: {
      type: Boolean,
      default: true
    },
    sliderColor: {
      type: String,
      default: '#5C2DD3'
    },
    tabColor: {
      type: String,
      default: '#5C2DD3'
    },
    menuItems: {
      type: Array,
      required: true
    },
    menuFile: {
      required: true
    }
  },
  data() {
    return {
      internalMenuFile: this.menuFile
    }
  },
  watch: {
    menuFile(newVal) {
      this.internalMenuFile = newVal
    },
    internalMenuFile(newVal) {
      this.$emit('update:menu', newVal)
    }
  },
  methods: {
    toggleTabs() {}
  }
}
</script>

<style lang="scss">
.menu-block-tabs {
  display: flex;
  align-items: center;
  .v-slide-group__content {
    margin-left: 0px;
  }
  .v-tabs-bar {
    height: 33px !important;
  }
  .v-slide-group__wrapper {
    border-radius: 8px;
  }
  .tab-menu-block {
    &.v-tab {
      text-decoration: none;
      text-transform: none;
      color: #2f2f2f !important;
      line-height: 21px !important;
      padding: 0px 16px 0px 16px !important;
      font-size: 13.5px !important;
      font-weight: 600 !important;

      letter-spacing: 0px;
      text-align: left;
      &.v-tab--active {
        color: #5c2dd3 !important;
      }
    }
  }
}
</style>
