<template>
  <div>
    <!-- Modal Display pdf -->
    <v-dialog v-model="pdfModal" max-width="800">
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closePdfModal">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <iframe
            id="iframe-scroller"
            style="width: 100%; height: 70vh;"
            :src="pdfUrl"
          ></iframe>
        </v-card-text>
      </v-card>
    </v-dialog>

    <div
      class="grid-proposals"
      :class="{ 'scroll-catalog': styleComponents == 'catalog' }"
    >
      <v-row>
        <v-col 
          v-for="proposal in computedTableBoard"
          :key="proposal.id"
          class="d-flex child-flex"
          cols="2"
        >
        <!-- {{proposal.files}} -->
       
        <!-- Affichage des pdf  -->
          <v-card elevation="0" >
            <div
              v-if="isPdf(proposal.file_one)"
              @click="openPdfModal(proposal.file_one.url)"
              class="pdf-container"
            >
              <iframe
                id="iframe-scroller"
                :src="proposal.file_one.url"
                style="height: 80px; width:100%"
              ></iframe>
              
            </div>
        <!-- Affichage des images -->
            <div
              v-else-if="
                imageForExtension(proposal.file_one) &&
                imageForExtension(proposal.file_one).image
              "
              @click.prevent.stop="openLightbox(proposal.files)"
            >
              <v-img
                style="height: 80px !important;"
                :src="imageForExtension(proposal.file_one).image"
                :lazy-src="imageForExtension(proposal.file_one).image"
                aspect-ratio="3"
                class="grey lighten-2"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>

            <div v-else class="file-extension" style="height: 55% !important;">
              <v-icon
                :class="{
                  'icon-extension-file': styleComponents == 'catalog',
                  'icon-extension-file-projet': styleComponents != 'catalog',
                }"
                :color="colorFileExtension(proposal.file_one)"
                dark
                large
              >
                {{ imageForExtension(proposal.file_one) }}
              </v-icon>
            </div>

            <v-card-title
              style="height: 40px; width:100%;" 
              @click="openPdfModal(proposal.file_one.url)"
              class="block-flex-align-space-between color-card-title"
            >

              <div class="label">
                <div class="label-1">
                  {{
                    styleComponents == "document"
                      ? proposal.file_one && proposal.file_one.title
                        ? proposal.file_one.title
                        : ""
                      : proposal.designation
                      ? proposal.designation
                      : ""
                  }}
                </div>
                <div
                  v-if="proposal.type_show"
                  class="label-2"
                  :title="proposal.type_show"
                >
                  {{ proposal.type_show }}  
                </div>
              </div>
              <div style="margin-top: -20px;padding-bottom: 20px;">
                <v-menu offset-y >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon small fab>
                      <v-icon>mdi-dots-horizontal</v-icon></v-btn
                    >
                  </template>
                  <v-list >
                    <v-list-item
                      v-for="(item, index) in menu"
                      :key="index"
                      link
                      @click.prevent.stop="handleClick(item, proposal)"
                    >
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="isPdf(proposal.file_one)">
                      <v-list-item-title @click.prevent.stop="openPdfModal(proposal.file_one.url)" style="cursor: pointer;" :hover="{ backgroundColor: '#f0f0f0' }">  {{ $i18n.locale === 'fr' ? 'Voir' : 'Show' }}</v-list-item-title>
                    </v-list-item>

                  </v-list>
                </v-menu>
              </div>
            </v-card-title>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          v-if="!computedTableBoard || !computedTableBoard.length"
          class="div-aucun"
        >
          <div class="titre">
            {{ getNoProposalMessage }}
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- footer  -->
    <v-row class="row-footer">
      <v-col class="total-footer">
        <span v-if="total">{{ $t("total") }} : {{ total }}</span>
      </v-col>
      <v-col class="pagination-footer">
        <v-pagination
          v-model="page"
          :length="computedLastPage"
          @input="pagination"
          :total-visible="4"
        ></v-pagination
      ></v-col>
    </v-row>
    <div>
     
       <!-- Lightbox -->
    <vue-easy-lightbox
      :visible="lightboxVisible"
      :imgs="lightboxImages"
      @hide="closeLightbox"
    ></vue-easy-lightbox>
      </div>
   
  </div>
</template>
<script>
import VueEasyLightbox from "vue-easy-lightbox";

// import pdf from 'vue-pdf'
export default {
  components: {
    VueEasyLightbox,
    // pdf
  },
  props: {
    isFile: { required: false },
    styleComponents: { required: false },
    computedTableBoard: { required: false },
    total: { required: false, default: null },
    computedLastPage: { required: true },
    pagePagination: { required: false },
  },
  data() {
    return {
      pdfModal: false,
      pdfUrl: "",
      lightboxVisible: false,
      lightboxImages: [],
      menu: this.isFile
        ? [
            {
              title: this.$i18n.locale == "fr" ? "Télécharger" : "Download",
              value: 2,
            },

            {
              title: this.$i18n.locale == "fr" ? "Supprimer" : "Delete",
              value: 4,
            },
          ]
        : [
            {
              title: this.$i18n.locale == "fr" ? "Modifier" : "Edit",
              value: 1,
            },
            {
              title: this.$i18n.locale == "fr" ? "Supprimer" : "Delete",
              value: 4,
            },
          ],
      page: this.pagePagination ? this.pagePagination : 1,
    };
  },
  methods: {
    openPdfModal(url) {
      this.pdfUrl = url;
      this.pdfModal = true;
    },
    closePdfModal() {
      this.pdfModal = false;
    },

    iframeLoaded() {
      // Ajoutez un écouteur d'événements à l'iframe après son chargement
      const iframe = this.$refs.iframe.contentWindow;
      iframe.addEventListener("click", this.handleIframeClick);
    },
    handleIframeClick(event) {
      // Vérifiez si l'élément cliqué est celui que vous souhaitez ouvrir dans le lightbox
      if (event.target.tagName === "IMG") {
        const imageUrl = event.target.src;
        this.openLightbox(imageUrl);
      }
    },
    openLightbox(files) {
        this.lightboxImages = []; // Réinitialiser le tableau des images
        for (let i = 0; i < files.length; i++) {
          this.lightboxImages.push(files[i].url); // Ajouter chaque URL d'image au tableau
        }
        this.lightboxVisible = true; // Afficher le lightbox
    },
    closeLightbox() {
      this.lightboxVisible = false;
      this.lightboxImages = [];
    },

    isPdf(file) {
      // Vérifiez si le fichier a une extension .pdf ou s'il s'agit d'un type MIME de PDF
      // Vous pouvez personnaliser cette logique selon vos besoins
      if (file && file.extension && file.extension.toLowerCase() === "pdf") {
        return true;
      }
      return false;
    },
    handleClick(item, body) {
      // Update proposals
      if (item.value == 2) this.$emit("download", body.file_one);

      if (item.value == 1) this.$emit("edit", body);
      // delete proposals
      else if (item.value == 4)
        this.$emit("deleted", this.isFile ? body.file_one : body);
    },
    //Pagination
    pagination(paginate) {
      this.page = paginate;
      this.$emit("pagination", this.page);
    },
  },
  computed: {
    getNoProposalMessage: function () {
      if (this.styleComponents === "catalog") {
        return this.$i18n.locale === "fr" ? "Aucune ressource" : "No resource";
      } else if (this.styleComponents === "document") {
        return this.$i18n.locale === "fr" ? "Aucun document" : "No document";
      } else {
        return this.$i18n.locale === "fr"
          ? "Aucune proposition"
          : "No proposal";
      }
    },
    imageForExtension() {
      return (file) => {
        if (file && file.extension) {
          switch (file.extension.toLowerCase()) {
            case "jpg":
            case "jpeg":
            case "png":
            case "bmp":
            case "gif":
              return { image: file.url };
            case "doc":
            case "docx":
              return "mdi-file-word";
            case "ppt":
            case "pptx":
              return "mdi-file-powerpoint";
            case "xls":
            case "xlsx":
              return "mdi-file-excel";
            case "pdf":
              return "mdi-file-pdf-box";
            case "txt":
            case "odt":
            case "ods":
              return "mdi-file-document";
            default:
              return "mdi-file";
          }
        }
        return "mdi-file-outline";
      };
    },
    colorFileExtension() {
      return (file) => {
        if (file && file.extension) {
          switch (file.extension.toLowerCase()) {
            case "jpg":
            case "jpeg":
            case "png":
            case "bmp":
            case "gif":
              return "";
            case "doc":
            case "docx":
              return "#1a73e8";
            case "ppt":
            case "pptx":
              return "#cb4424";
            case "xls":
            case "xlsx":
              return "#1d6b40";
            case "pdf":
              return "#e61b23";
            case "txt":
            case "odt":
            case "ods":
              return "#44abca";
            default:
              return "";
          }
        }
        return "#f6f6f6";
      };
    },
  },
};
</script>
<style lang="scss" scoped>
.pdf-container {
  position: relative;
}

.pdf-eye-icon {
  margin-top: -15px;
  top: 8px;
  left: 90%;
  
}
.grid-proposals {
  // height: calc(100vh - 424px);
  height: calc(100vh - 474px);
  margin-bottom: 14px;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #bdbdbd;
    cursor: pointer !important;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: 0px 1px 0px 0px #0000001f inset;
    box-shadow: 0px 1px 0px 0px #0000001f inset;
    border-radius: 4px;
    background-color: #eeeeee;
  }
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &.scroll-catalog {
    height: calc(100vh - 235px);
  }
  .icon-extension-file {
    text-align: center;
    display: block;
    font-size: 60px !important;
  }
  .icon-extension-file-projet {
    text-align: center;
    display: block;
    font-size: 60px !important;
  }
  .file-extension {
    height: 63%;
    padding: 31px;
    border: 1px solid #f6f6f6;
  }
}
.label {
  width: 60%;
  margin-top: -15px;
  padding-bottom: 20px;
  .label-1 {
    margin-left: -10px;
    min-width: 100% !important;
    font-size: 11px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    line-height: 19.5px;
    color: rgba(47, 47, 47, 1);
    text-transform: capitalize;
    white-space: nowrap;
    min-width: 16vh;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .label-2 {
    margin-left: -10px;
    font-size: 10px;
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    line-height: 18.59px;
    color: rgba(47, 47, 47, 1);
    white-space: nowrap;
    min-width: 100% !important;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
.color-card-title {
  background-color: #f6f6f6;
  // width: 250px
  // flex-wrap: nowrap !important;
}

</style>
