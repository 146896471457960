<template>
  <div class="historique-activity">
    <!-- DESCRIPTION HISTOY ACTIVITY EVENT -->
    <div class="histoy" v-if="history && history.length">
      <div v-for="hist in history" :key="hist.id" class="activity">
        <div class="content-history-activity">
          <div class="icon-type-activity">
            <!------------ Icon pour chaque Event (Email-Task-Message-Note-whatsApp)  --------->
            <font-awesome-icon
              :icon="
                hist?.type == 'email'
                  ? ['far', 'envelope']
                  : hist?.type == 'message'
                  ? ['far', 'phone']
                  : hist.type_event && hist.type_event.icon
                  ? [
                      getIconFormate(hist.type_event.icon, 0),
                      getIconFormate(hist.type_event.icon, 1),
                    ]
                  : ['far', 'calendar-lines']
              "
              class="mr-2"
            ></font-awesome-icon>
          </div>

          <div class="ml-3">
            <div class="title-history">
              {{
                hist?.type == "email"
                  ? $t("email")
                  : hist?.type == "message"
                  ? $t("message")
                  : hist.type_event && hist.type_event.designation
                  ? hist.type_event.designation
                  : ""
              }}
              {{
                hist?.type == "email" || hist?.type == "message"
                  ? $t("send_by") + " "
                  : $t("created_by") + " "
              }}{{
                hist.created_by && hist.created_by.full_name
                  ? hist.created_by.full_name
                  : hist.created_by
              }}
            </div>
            <div class="date-history">{{ hist.date | formatDate }}</div>
            <div
              class="date-history"
              v-if="hist.titre && hist?.type == 'email'"
            >
              {{ $t("subject") }} : {{ hist.titre }}
            </div>
            <div class="date-history" v-if="hist.to.length">
              {{ $t("to") }} : {{ hist.to[0].value }}
              <span v-if="hist.to.length > 1" class="more">
                {{ $t("more", { nb: hist.to.length - 1 }) }}
              </span>
            </div>
            <div class="text-container" ref="textContainer">
              <div
                v-if="hist?.type == 'email'"
                class="description-history"
                :class="{ collapsed: hist.showText }"
                v-html="hist.description"
              ></div>
              <div
                v-else
                class="description-history"
                :class="{ collapsed: hist.showText }"
              >
                {{ hist.description }}
              </div>
              <div
                v-if="showToggleButton(hist)"
                class="color-blue show-more-history ml-1 mt-2"
                @click.prevent.stop="toggleTextVisibility(hist)"
              >
                {{
                  hist.showText
                    ? $i18n.locale == "fr"
                      ? "Afficher moins"
                      : "Show less"
                    : $i18n.locale == "fr"
                    ? "Afficher plus"
                    : "Show more"
                }}
                <v-icon class="color-blue font-sz-20">{{
                  hist.showText ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
              </div>
            </div>
          </div>
          <!---------------- Menu Voir Modal Historique  ------------>
          <div
            class="mt-4"
            @click="toggleMenu(hist.id)"
            style="text-align: right; margin-left: auto;"
          >
              <div class="header-action-immo">
                <font-awesome-icon :icon="['fas', 'ellipsis']" />
              </div>

              <!-- Affichage du button "Voir" dans la liste déroulante -->
              <div
                class="menu-text"
                @click="openModal(hist)"
                v-if="isOpen[hist.id]"
              >
                {{ $t("voir") }}
              </div>
            
          </div>

          <!------------------------------- 5 Task de type (Email-Task-Message-Note-whatsApp)  ------------------------>
          <!-----------------------Modal Task  -------------------------->
          <taskModal
            v-if="
              ModalTask[hist.id] &&
              (hist?.type_event?.designation === 'Task' ||
                hist?.type_event?.designation === 'Note' ||
                hist?.type_event?.designation === 'Email' ||
                hist?.type_event?.designation === 'WhatsApp' ||
                hist?.type_event?.designation === 'SMS')
            "
            :hist="hist"
            @close="closeModal('task')"
          />
          <!------------------------------- ///// End ModalTask ///////// ---------------------------->

          <!----------------------------------Modal Action Event (Message - Email)  ----------------------------------->
          <EmailOrMessage
            v-if="
              ModalEmailOrSms[hist.id] &&
              (hist?.type === 'email' || hist?.type === 'SMS')
            "
            :DataEvent="hist"
            @close="closeModal('emailOrSms')"
          />
          <!--------------------------------- End Modal Message - Email  -------------------------------->
        </div>
        <v-divider></v-divider>
      </div>
    </div>
    <div v-else class="div-aucun">
      <div>
        {{ $i18n.locale == "fr" ? "Aucun historique" : "No history" }}
      </div>
    </div>
    <!-- FOOTER PAGINATION  -->
    <div class="task-footer">
      <v-row class="row-footer">
        <v-col class="total-footer">
          <span v-if="countPagination"
            >{{ $t("total") }} :
            {{ Math.min(perPage * page, countPagination) }} /
            {{ countPagination }}</span
          >
        </v-col>
        <v-col class="pagination-footer">
          <v-pagination
            v-model="page"
            :length="totalPage"
            @input="pagination"
            :total-visible="4"
          ></v-pagination
        ></v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import taskModal from "./ModalShowTask/TaskModal";
import EmailOrMessage from "./EmailAndMessage/EmailOrMessageShow.vue";
export default {
  name: "historique-activity",

  props: {
    history: { required: true },
    loading: { required: false },
    countPagination: { required: true },
    totalPage: { required: true },
    perPage: { required: true },
  },
  data() {
    return {
      ModalEmailOrSms: {}, // Utilisé pour contrôler l'ouverture du modal
      ModalTask: {}, // Utilisé pour contrôler l'ouverture du modal
      isOpen: {}, // Initialiser isOpen comme un objet vide
      toggleHeight: 22,
      maxContentLength: 150,
      page: 1,
    };
  },
  components: {
    taskModal,
    EmailOrMessage,
  },
  methods: {
    closeModal(type) {
      if (type == "task") {
        this.ModalTask = {};
        return;
      } else if (type == "emailOrSms") {
        this.ModalEmailOrSms = {};
        return;
      }
    },

    toggleMenu(histId) {
      // Inverse la valeur de isOpen pour l'historique spécifique
      this.$set(this.isOpen, histId, !this.isOpen[histId]);
    },

    openModal(hist) {
      // Ouvre le modal pour l'historique spécifique si le type_event est 'Task'
      if (
        hist.type_event &&
        (hist.type_event?.designation === "Task" ||
          hist.type_event?.designation === "Note" ||
          hist.type_event?.designation === "Email" ||
          hist.type_event?.designation === "WhatsApp" ||
          hist.type_event?.designation === "SMS")
      ) {
        this.$set(this.ModalTask, hist.id, true);
      }

      // Ouvre le modal pour l'historique spécifique si le type_event est 'SMS-Email'
      else if (hist && (hist.type === "SMS" || hist.type === "email")) {
        this.$set(this.ModalEmailOrSms, hist.id, true);
      }
    },
    toggleTextVisibility(hist) {
      hist.showText = !hist.showText;
    },
    refreshTextContainer() {
      // Rafraîchir la référence du conteneur de texte
      if (this.$refs.textContainer) {
        this.$refs.textContainer = Array.isArray(this.$refs.textContainer)
          ? this.$refs.textContainer
          : [this.$refs.textContainer];
      } else {
        this.$refs.textContainer = [];
      }
    },
    pagination(event) {
      this.$emit("handlePagination", event);
      this.refreshTextContainer();
    },
  },
  computed: {
    ...mapGetters(["getIconFormate", "getEventLoading"]),
    showToggleButton() {
      return (history) => {
        if (history && history.description) {
          const containers = this.$refs.textContainer;
          if (containers && containers.length > 0) {
            for (const container of containers) {
              const containerHeight = container.offsetHeight;
              if (
                containerHeight > this.toggleHeight &&
                history.description.length > this.maxContentLength
              ) {
                return true;
              }
            }
          }
        }
        return false;
      };
    },
  },
  filters: {
    formatDate: function (value) {
      // Convertir la chaîne en objet Date
      var dateObject = new Date(value);

      // Obtenir les noms des mois en trois lettres
      var monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      // Formater la date selon le format souhaité
      return (
        monthNames[dateObject.getMonth()] +
        ", " +
        dateObject.getDate().toString().padStart(2, "0") +
        ", " +
        dateObject.getFullYear()
      );
    },
  },
  watch: {
    loading() {
      this.refreshTextContainer();
    },
    getEventLoading() {
      this.refreshTextContainer();
    },
  },
};
</script>
<style lang="scss" scoped>
.historique-activity {
  .custom-drop-down-menu {
    position: relative;
    cursor: pointer;
  }
  .menu-text {
    color: #2f2f2f;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    font-family: Montserrat;
    text-align: left;
    font-size: small;
    width: 8rem;
    height: 4rem;
    padding: 0.5rem;
    border-radius: 8px;
    margin-top: 5px;
    border: 1px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Ajouter une ombre */
  }

  .activity {
    &:hover {
      background: #f0f4f8;
    }
  }
  .content-history-activity {
    display: flex;
    padding: 15px 20px;
    .icon-type-activity {
      top: 4px;
      position: relative;
    }
    .title-history {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      // font-family: 'Montserrat', sans-serif;
      color: #000000 !important;
      text-transform: capitalize;
    }
    .date-history {
      font-size: 12px;
      line-height: 24px;
      font-weight: 500;
      font-family: "Montserrat", sans-serif;
      color: rgba(80, 80, 80, 1) !important;
      position: relative;
      top: -2px;
    }
    .description-history {
      font-size: 12px;
      line-height: 24px;
      font-weight: 400;
      font-family: "Montserrat", sans-serif;
      color: #2f2f2f !important;
      text-align: justify;
      // width: 60%;
      max-height: 3.5em;
      overflow: hidden;
      position: relative;
    }
    .collapsed {
      max-height: none;
    }
    .text-content {
      margin: 0;
      padding-right: 40px; /* Pour laisser de la place pour le bouton */
    }

    .show-more-history {
      font-size: 12px;
      // line-height: 24px;
      font-weight: 500;
      font-family: "Montserrat", sans-serif;
    }
    .color-blue {
      color: rgba(45, 91, 255, 1);
      font-family: "Montserrat", sans-serif;
    }
    .more {
      font-weight: 600;
      font-size: 11px;
      margin-left: 10px;
    }
  }
  .row-footer {
    background: #fff;
    margin: 0px;
    margin-top: 6px;
    .total-footer {
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0em;
      color: #212121;
    }
    .pagination-footer {
      padding: 1px;
      .v-pagination {
        justify-content: flex-end;
      }
      .v-pagination__item,
      .v-pagination__navigation,
      .v-pagination__more {
        height: 28px;
        width: 28px;
        min-width: 28px;
        border-radius: 8px;
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-align: center;
      }
      .v-pagination__navigation--disabled {
        background: #e6e6e6 !important;
        i {
          color: #b4b0c9 !important;
        }
      }
      .v-pagination__item,
      .v-pagination__more {
        color: #45148f;
        border: 1px solid #e9f6fa;
        background: #fff;
      }
      .v-pagination__more {
        align-items: normal;
        font-size: 19px !important;
        position: relative;
      }
      .v-pagination__more::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 11px 0px 0px;
        border-color: transparent #45148f transparent transparent;
        border-radius: 5px;
      }
      .v-pagination__item--active {
        background-color: #45148f !important;
        color: #f2f2f2 !important;
        border: none !important;
      }
      .v-pagination__navigation {
        background: #45148f;
        color: #f2f2f2;
      }
      .v-pagination__navigation,
      .v-pagination__item--active {
        border: none;
        i {
          color: #f2f2f2;
        }
      }
    }
  }
}
</style>
