<template>
  <div class="action-card">
    <div v-for="actionEvent in data" :key="actionEvent.id">
      <div class="content-action-card">
        <div class="icon-type">
          <font-awesome-icon
            :icon="
              actionEvent?.type == 'email'
                ? ['far', 'envelope']
                : actionEvent?.type == 'message'
                ? ['far', 'phone']
                : ['far', 'calendar-lines']
            "
            class="mr-2"
          ></font-awesome-icon>
        </div>
        <div class="width-100">
          <div class="d-flex  justify-space-between">
            <div class="title-action-card">
              {{
                actionEvent?.type == 'email'
                  ? $t('email')
                  : actionEvent?.type == 'message'
                  ? $t('message')
                  : ''
              }}
              {{ $t('to_send') + ' '
              }}{{
                actionEvent.created_by && actionEvent.created_by.full_name
                  ? actionEvent.created_by.full_name
                  : actionEvent.created_by
              }}
            </div>
            <div class="">
              <custom-drop-down-menu>
                <template #header>
                  <div class="header-action-immo">
                    <font-awesome-icon :icon="['fas', 'ellipsis']" />
                  </div>
                </template>
                <template #body>
                  <div class="action-card-list">
                    <div
                      class="item-action"
                      @click="updateEventAction(actionEvent)"
                    >
                      {{ $t('update') }}
                    </div>
                    <div
                      class="item-action"
                      @click="deleteEventAction(actionEvent)"
                    >
                      {{ $t('deleted') }}
                    </div>
                  </div>
                </template>
              </custom-drop-down-menu>
            </div>
          </div>
          <div class="date-action-card">
            {{ actionEvent.scheduled_at }}
          </div>
          <div
            class="date-action-card"
            v-if="actionEvent.subject && actionEvent?.type == 'email'"
          >
            {{ $t('subject') }} : {{ actionEvent.subject }}
          </div>
          <div class="date-action-card" v-if="actionEvent.to.length">
            {{ $t('to') }} : {{ actionEvent.to[0].value }}
            <span v-if="actionEvent.to.length > 1" class="more">
              {{ $t('more', { nb: actionEvent.to.length - 1 }) }}
            </span>
          </div>
          <div class="text-container" ref="textContainerAction">
            <div
              v-if="actionEvent?.type == 'email'"
              class="description-action-card"
              :class="{ collapsed: actionEvent.showText }"
              v-html="actionEvent.message"
            ></div>
            <div
              v-else
              class="description-action-card"
              :class="{ collapsed: actionEvent.showText }"
            >
              {{ actionEvent.message }}
            </div>
            <div
              v-if="showToggleButton(actionEvent)"
              class="color-blue show-more-action-card ml-1 mt-2"
              @click.prevent.stop="actionEvent.showText = !actionEvent.showText"
            >
              {{
                actionEvent.showText
                  ? $i18n.locale == 'fr'
                    ? 'Afficher moins'
                    : 'Show less'
                  : $i18n.locale == 'fr'
                  ? 'Afficher plus'
                  : 'Show more'
              }}
              <v-icon class="color-blue font-sz-20">{{
                actionEvent.showText ? 'mdi-chevron-up' : 'mdi-chevron-down'
              }}</v-icon>
            </div>
          </div>
        </div>
      </div>
      <v-divider></v-divider>
    </div>
    <portal to="portal-modal-immobilier">
      <action-msg
        v-if="openModalUpdate"
        @close="closeModal()"
        :actionEvent="actionToUpdate"
        :title="titleModalTask"
        :validateFunction="handelUpdateAction"
        :fields="fieldsAction"
      ></action-msg
    ></portal>
    <div class="message width-100" v-if="showAlert">
      <AlertAction :message="alertMessage" :typeError="alertType"></AlertAction>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import customDropDownMenu from '../immoTable/component/customDropDownMenu.vue'
export default {
  props: { data: { required: true } },
  data() {
    return {
      openModalUpdate: false,
      titleModalTask: null,
      actionToUpdate: null,
      fieldsAction: [],
      showAlert: false,
      alertMessage: null,
      alertType: 'succes'
    }
  },
  methods: {
    ...mapActions([
      'refreshHistoryActivity',
      'updateActions',
      'fetchHistoryActivtyEvent'
    ]),
    async updateEventAction(item) {
      if (item.type == 'message') {
        this.titleModalTask = 'message'
        this.fieldsAction = ['phone', 'textArea']
      }

      if (item.type == 'email') {
        this.titleModalTask = 'email'
        this.fieldsAction = ['email', 'subject', 'editor']
      }
      this.actionToUpdate = item
      this.openModalUpdate = true
    },
    async handelUpdateAction(item) {
      let data = { ...item }
      if (data.scheduled_at) {
        data.scheduled_at = this.convertDate(data.scheduled_at)
      }
      let attach_id = data.attachments_action.map(i => i.id)
      const differences = this.actionToUpdate.attachments_action
        .map(i => i.id)
        .filter(num => !attach_id.includes(num))
      if (differences.length) {
        data.attachments_to_delete = differences
      }
      const resActions = await this.updateActions({
        data: data,
        origin: this.actionToUpdate
      })
      if (resActions.succes) {
        this.refreshHistoryActivity()
      }
      if (resActions.succes) {
        this.alertType = 'succes'
        this.alertMessage =
          this.$t(this.titleModalTask) + ' ' + this.$t('update_succes')
        this.showAlert = true
      }
      setTimeout(() => {
        this.showAlert = false
      }, 4000)
      return resActions
    },
    refreshHistoryActivity() {
      if (this.getCurentProjectType?.id) {
        this.fetchHistoryActivtyEvent({
          page: 1,
          per_page: 10,
          type_projet_id: this.getCurentProjectType.id,
          projet_id: this.getProjectsExtraProject.id
        })
      }
    },
    convertDate(inputDate) {
      const [datePart, timePart, meridian] = inputDate.split(' ')
      const [year, month, day] = datePart.split('-')
      let [hours, minutes] = timePart.split(':')

      if (meridian.toLowerCase() === 'pm' && hours !== '12') {
        hours = String(parseInt(hours, 10) + 12)
      }

      if (meridian.toLowerCase() === 'am' && hours === '12') {
        hours = '00'
      }

      return `${day}-${month}-${year} ${hours.padStart(2, '0')}:${minutes}:00`
    },
    deleteEventAction() {},
    closeModal() {
      this.openModalUpdate = false
      this.actionToUpdate = null
      this.fieldsAction = []
    }
  },
  computed: {
    ...mapGetters(['getCurentProjectType', 'getProjectsExtraProject']),
    showToggleButton() {
      return actionEvent => {
        if (actionEvent && actionEvent.description) {
          const containers = this.$refs.textContainerAction
          if (containers && containers.length > 0) {
            for (const container of containers) {
              const containerHeight = container.offsetHeight
              if (
                containerHeight > this.toggleHeight &&
                actionEvent.description.length > this.maxContentLength
              ) {
                return true
              }
            }
          }
        }
        return false
      }
    }
  },
  components: {
    customDropDownMenu,
    actionMsg: () => import('../immoTable/component/action/actionMsg.vue')
  }
}
</script>

<style scoped lang="scss">
.action-card {
  .task-card-block {
    flex-direction: column;
    align-items: center;
    .task-card-sub-block {
      display: flex;
      align-items: center;
      font-size: 11px;
    }
  }
  .show-more-action-card {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Montserrat', sans-serif;
  }
  .content-action-card {
    display: flex;
    padding: 15px 20px;

    .icon-type {
      top: 4px;
      position: relative;
    }
    .title-action-card {
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      color: #000000 !important;
      text-transform: capitalize;
    }
    .date-action-card {
      font-size: 12px;
      line-height: 24px;
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
      color: rgba(80, 80, 80, 1) !important;
      position: relative;
      top: -2px;
    }
    .description-action-card {
      font-size: 12px;
      line-height: 24px;
      font-weight: 400;
      font-family: 'Montserrat', sans-serif;
      color: #2f2f2f !important;
      text-align: justify;
      max-height: 3.5em;
      overflow: hidden;
      position: relative;
    }
    .collapsed {
      max-height: none;
    }
    .text-content {
      margin: 0;
      padding-right: 40px;
    }

    .show-more-action-card {
      font-size: 12px;
      font-weight: 400;
      font-family: 'Montserrat', sans-serif;
    }
    .color-blue {
      color: rgba(45, 91, 255, 1);
      font-family: 'Montserrat', sans-serif;
    }
    .more {
      font-weight: 600;
      font-size: 11px;
      margin-left: 10px;
    }
  }
}
.action-card-list {
  .item-action {
    padding: 5px;

    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    cursor: pointer;
    &:hover {
      background: #ece8f44d;
    }
  }
}
</style>
